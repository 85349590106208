const getProjectsAmountPerCountry = (projects = []) => {
  const results = Object.values(
    projects.reduce((acc, project) => {
      acc[project.location.name] = acc[project.location.name]
        ? {...project, count: 1 + acc[project.location.name].count}
        : {...project, count: 1};
      return acc;
    }, {})
  )

  const formattedResults = results.map(project => {
    // borra tildes
    const normalizedStrs = project.location.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    return {
      className: `c-${normalizedStrs}`,
      count: project.count
    }
  })

  return formattedResults
}

export {getProjectsAmountPerCountry}