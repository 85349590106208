import styled from "styled-components";
import Modal from "styled-react-modal";

const StyledModal = Modal.styled`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: ${(props) => props.display};
  padding: ${(props) => props.padding};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyCont};
  background-color: ${(props) => props.background || "white"};
  border-radius: ${(props) => props.borderRadius};
`;
const ModalWrapper = styled.div`
  width: 100%;
  display: block;
`;

const Title = styled.h2`
  margin-top: 2rem;
  font-size: ${(props) =>
    props.fontSizes ? props.fontSizes : props.theme.fontSizes.extended};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semiBold};

  color: ${(props) => (props.color ? props.color : props.theme.colors.gray)};
`;

export { StyledModal, ModalWrapper, Title };
