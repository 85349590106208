import { groupBy } from "../utils/groupBy";

const getGroupedProjectsPerStatus = (projects = [], selectedYears = []) => {
  const groupedDataByStatus = groupBy(
    projects.map((p) => {
      return {
        name: p.name,
        topicName: p.topic?.name,
        topicId: p.topic?.id,
        date: `${new Date(p.presented).getMonth() + 1}/${new Date(
          p.presented
        ).getFullYear()}`,
        statusId: p.status?.id,
        statusName: p.status?.name,
        impactId: p.impact?.id,
        impactName: p.impact?.name,
        presented: p.presented ? p.presented : "-",
        voted: p.voted ? p.voted : "-",
      };
    }),
    "statusId"
  );

  Object.values(groupedDataByStatus).forEach((group) => {
    for (let year = selectedYears[1]; year >= selectedYears[0]; year--) {
      for (let month = 12; month >= 1; month--) {
        group.unshift({
          date: `${month}/${year}`,
        });
      }
    }
  });

  const groupedTopics = groupBy(
    projects
      .filter((p) => p.status.id !== 1)
      .map((p) => {
        return {
          topicName: p.topic.name,
          topicId: p.topic.id,
        };
      }),
    "topicId"
  );

  return {
    groupedDataByStatus,
    groupedTopics,
  };
};

export { getGroupedProjectsPerStatus };
