import {
  AlignTop,
  Desktop,
  Gear,
  PresentationChart,
  Stack,
  Users,
} from "shared/assets/svgs/SidebarIcons";

const ADMIN_ROLES = [
  { text: "Desktop", route: "desktop", icon: <Desktop /> },
  { text: "Bills, Acts & Laws", route: "policies", icon: <Stack /> },
  { text: "Tracker", route: "tracker", icon: <AlignTop /> },
  { text: "Reports", route: "reports", icon: <PresentationChart /> },
  { text: "Users", route: "users", icon: <Users /> },
  { text: "Settings", route: "settings", icon: <Gear /> },
];

const COORDINATOR_ROLES = [
  { text: "Desktop", route: "desktop", icon: <Desktop /> },
  { text: "Bills, Acts & Laws", route: "policies", icon: <Stack /> },
  { text: "Tracker", route: "tracker", icon: <AlignTop /> },
  { text: "Reports", route: "reports", icon: <PresentationChart /> },
];

const CONSULTANT_ROLES = [
  { text: "Bills, Acts & Laws", route: "policies", icon: <Stack /> },
];

export { ADMIN_ROLES, CONSULTANT_ROLES, COORDINATOR_ROLES };
