import { Header } from "UI/molecules";
import { useGetProjects } from "queries/projects";
import { Spinner } from "shared/assets/svgs";
import { AppFlowByRole } from "pages/AppFlowByRole";

const AuthenticatedApp = ({ user }) => {
  const { data: projects, isLoading: isProjectsLoading } = useGetProjects();

  return (
    <>
      {isProjectsLoading ? (
        <Spinner />
      ) : (
        <>
          <Header
            userName={`${user.first_name} ${user.last_name}`}
            userRole={user?.role}
            search={"Search legislation..."}
            buttonText={"New Legislation"}
          />
          <AppFlowByRole role={user?.role} projects={projects} />
        </>
      )}
    </>
  );
};

export { AuthenticatedApp };
