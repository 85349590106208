import React from 'react'

const AlignTopIcon = ({ color }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.875 17.5V7C21.875 6.51675 21.4832 6.125 21 6.125H16.625C16.1418 6.125 15.75 6.51675 15.75 7V17.5C15.75 17.9832 16.1418 18.375 16.625 18.375H21C21.4832 18.375 21.875 17.9832 21.875 17.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.375 6.125H7C6.51675 6.125 6.125 6.51675 6.125 7V21.875C6.125 22.3582 6.51675 22.75 7 22.75H11.375C11.8582 22.75 12.25 22.3582 12.25 21.875V7C12.25 6.51675 11.8582 6.125 11.375 6.125Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {AlignTopIcon}