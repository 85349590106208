import React, { useState } from "react";
import {
  Countries,
  Topics,
  Entities,
  Statuses,
  TabsLayout,
  Probabilities,
  Impacts,
  HasWorked,
} from "./UI/templates";

const layoutsTabs = {
  countries: <Countries />,
  topics: <Topics />,
  entities: <Entities />,
  statuses: <Statuses />,
  probabilities: <Probabilities />,
  impacts: <Impacts />,
  has_worked: <HasWorked />,
};

function Ajustes() {
  const [selectedTab, setSelectedTab] = useState("countries");

  const handleSelectedTab = (tab) => {
    return selectedTab === tab && "selected";
  };

  const handleLayoutsTabsWithExtendedProps = (props) => {
    return React.cloneElement(layoutsTabs[selectedTab], { ...props });
  };

  return (
    <div className="politicas-nuevo">
      <div className="dash-area">
        <p className="breadcrumb">Settings</p>
        <div className="tabs-card">
          <ul className="tab-menu">
            <li onClick={() => setSelectedTab("countries")}>
              <span className={`tab-item ${handleSelectedTab("countries")}`}>
                Country
              </span>
            </li>
            <li onClick={() => setSelectedTab("topics")}>
              <span className={`tab-item ${handleSelectedTab("topics")}`}>
                Type
              </span>
            </li>
            <li onClick={() => setSelectedTab("entities")}>
              <span className={`tab-item ${handleSelectedTab("entities")}`}>
                Entity
              </span>
            </li>
            <li onClick={() => setSelectedTab("statuses")}>
              <span className={`tab-item ${handleSelectedTab("statuses")}`}>
                Status
              </span>
            </li>
            <li onClick={() => setSelectedTab("probabilities")}>
              <span
                className={`tab-item ${handleSelectedTab("probabilities")}`}
              >
                Probability
              </span>
            </li>
            <li onClick={() => setSelectedTab("impacts")}>
              <span className={`tab-item ${handleSelectedTab("impacts")}`}>
                Impact
              </span>
            </li>
            <li onClick={() => setSelectedTab("has_worked")}>
              <span className={`tab-item ${handleSelectedTab("has_worked")}`}>
                Did strategy work
              </span>
            </li>
          </ul>

          <TabsLayout>{handleLayoutsTabsWithExtendedProps()}</TabsLayout>
        </div>
      </div>
    </div>
  );
}

export { Ajustes };
