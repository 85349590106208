import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProviders } from "auth";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

const errors = [401, 403, 404, 500];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
    },
  },
});

const root = document.getElementById("root");

render(
  <React.StrictMode>
    <AppProviders>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
    </AppProviders>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
