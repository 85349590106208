import React from "react";
import { Modal } from "UI/atoms/index";
import { Button } from "UI/atoms/index";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { Spinner } from "shared/assets/svgs";

const WarningModal = ({
  setIsOpenWarningModal,
  isWarningModalOpened,
  isDeleteProjectLoading,
  id,
  mutation,
  message,
  onSetIsModalOpen,
}) => {
  const handleAction = () => {
    mutation(id);
    setIsOpenWarningModal(false);
    onSetIsModalOpen(false);
  };
  return (
    <>
      <Modal
        isOpen={isWarningModalOpened}
        toggleModal={isWarningModalOpened}
        width="500px"
        padding="2rem"
        className="fecha-aprobado"
      >
        <h4 style={{ lineHeight: "1.5" }}>{message}</h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            width="133px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => setIsOpenWarningModal(false)}
          >
            Cancel
          </Button>
          {isDeleteProjectLoading ? (
            <Spinner />
          ) : (
            <Button
              width="133px"
              background="red"
              className="btn-with-icon white-icon"
              onClick={() => handleAction()}
            >
              <Check /> <span>Confirm</span>
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export { WarningModal };
