import { ModalWrapper, StyledModal, Title } from "./styles";

const Modal = ({
  children,
  isOpen,
  toggleModal,
  width,
  height,
  background,
  justifyCont = "center",
  alignItems = "center",
  style,
  display,
  padding,
  borderRadius = "10px",
  className,
  title,
  isActionDisabled
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={() => !isActionDisabled && toggleModal(false)}
      onEscapeKeydown={() => !isActionDisabled && toggleModal(false)}
      width={width}
      height={height}
      padding={padding}
      background={background}
      display={display}
      justifyCont={justifyCont}
      alignItems={alignItems}
      style={style}
      borderRadius={borderRadius}
      className={className}
    >
      <ModalWrapper>
        {title ? <Title color={"#6C6C6C"}>{title}</Title> : null}
        {children}
      </ModalWrapper>
    </StyledModal>
  );
};

export { Modal };
