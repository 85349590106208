import { useState } from "react";
import axios from "axios";
import { Button, Input, Label, ListItem } from "UI/atoms";
import { Trash, Plus } from "shared/assets/svgs";
import { DeleteModal } from "./organisms/DeleteModal";
import { CloudArrowDown } from "shared/assets/svgs/DesktopPageIcons";
import { Link } from "react-router-dom";
import { RegularText } from "UI/atoms";
import { ButtonActions } from "../../organisms/ButtonActions";

const API_KEY = process.env.REACT_APP_API_URL;

const Attachments = ({
  setSelectedTab,
  isEdit,
  responseError,
  formik,
  successResponse,
  isLastTab,
  projectId,
}) => {
  const successText = isEdit ? "changed" : "created";

  const [file, setFile] = useState(null);
  const [isDeleteModalOpen, setIsOpenDeleteModal] = useState(false);
  const { attachments } = formik.values;

  const handleFileUpload = (e) => {
    if (!e.target.value) return;
    if (attachments?.length > 0) {
      const currentValues = formik.getFieldProps("attachments");
      const newValue = [...currentValues?.value, { file: e.target.files[0] }];
      formik.setFieldValue("attachments", newValue);
    } else {
      formik.setFieldValue("attachments", [{ file: e.target.files[0] }]);
    }
  };

  const handleDeleteFile = (file) => {
    setFile(file);
    setIsOpenDeleteModal(true);
  };

  const handleDownload = (file) => {
    const url = `${API_KEY}/law_projects/attachment/${file.id}/`;
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = file.name;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data]));
        link.click();
      });
  };

  return (
    <>
      <div className="tablayer tab-adjuntos">
        <ul className="adjuntos">
          <div style={{ margin: "2rem 0" }}>
            <Label
              color="#2681FF"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Plus />
              <span style={{ margin: "0 12px" }}>Add attachment</span>
              <Input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
              />
            </Label>
          </div>
          {attachments?.map((uploadedFile, idx) => (
            <div
              key={idx}
              style={{
                background: "#F0F4F7",
                borderRadius: "10px",
                width: "auto",
              }}
            >
              <ListItem
                className="item"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0",
                }}
              >
                {/* if has id it means that the file already exists in the server so we're editing an existing project */}
                <p>
                  {!!uploadedFile?.id
                    ? uploadedFile?.name
                    : uploadedFile?.file?.name}
                </p>

                <Button
                  type="button"
                  height="30px"
                  width="30px"
                  background="#FF562F"
                  className="circle-btn"
                  onClick={() => handleDeleteFile(uploadedFile)}
                >
                  <Trash />
                </Button>
                <Button
                  type="button"
                  height="30px"
                  width="30px"
                  background="blue"
                  className="circle-btn"
                  onClick={() => handleDownload(uploadedFile)}
                >
                  <CloudArrowDown />
                </Button>
              </ListItem>
            </div>
          ))}
        </ul>
        {isDeleteModalOpen ? (
          <DeleteModal
            setIsOpenDeleteModal={setIsOpenDeleteModal}
            isDeleteModalOpen={isDeleteModalOpen}
            file={file}
            projectId={projectId}
            setFieldValue={formik.setFieldValue}
            attachments={attachments}
          />
        ) : null}
      </div>

      {successResponse && (
        <div style={{ display: "block", marginTop: "20px" }}>
          <RegularText size="16px" color="#2EC07F">
            Your project has been successfully {successText}!{" "}
            <Link to="/policies" style={{ fontSize: "16px", color: "#0379F2" }}>
              Click here
            </Link>{" "}
            to see list of projects.
          </RegularText>
        </div>
      )}

      <ButtonActions
        isEdit={isEdit}
        isLastTab={isLastTab}
        responseError={responseError}
        onSetSelectedTab={setSelectedTab}
        nextTab="topics"
        prevTap="parameters"
      />
    </>
  );
};

export { Attachments };
