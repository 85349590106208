import {
  ADMIN_ROLES,
  COORDINATOR_ROLES,
  CONSULTANT_ROLES,
} from "./list-items-by-role";

const handleSidebarAccordingToUserRole = (role) => {
  switch (true) {
    case role === "Administrators":
      return ADMIN_ROLES;
    case role === "Coordinators":
      return COORDINATOR_ROLES;
    case role === "Consultants":
      return CONSULTANT_ROLES;
    default:
      return;
  }
};

export { handleSidebarAccordingToUserRole };
