import React from 'react'
import { Modal } from 'UI/atoms'
import { AddEdit } from '../../molecules/AddEdit'

const AddEditModal = ({isOpenModal, setOpenAddOrEditModal, method, lookup = null, keyName, name, colors = undefined}) => {
  return (
    <Modal 
      isOpen={isOpenModal}
      toggleModal={setOpenAddOrEditModal}
      width='500px'
      height='fit-content'
      padding='25px 32px'
      className='topicos-ficha'
    >
      {method === 'PUT' && 
        <AddEdit 
          actionText='Edit' 
          method='PUT' 
          lookup={lookup} 
          setOpenAddOrEditModal={setOpenAddOrEditModal} 
          keyName={keyName}
          name={name}
          colors={colors}
        />
      }
      {method === 'POST' && 
        <AddEdit 
          actionText='Add' 
          method='POST' 
          setOpenAddOrEditModal={setOpenAddOrEditModal} 
          keyName={keyName}
          name={name}
          colors={colors}
        />
      }
    </Modal>
  )
}

export {AddEditModal}
    
