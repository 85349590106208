import axios from "axios";
import { login as authLogin } from "utils/api-client";

const localStorageAccessKey = "__auth_provider_token__";
const localStorageRefreshKey = "__auth_provider_refresh__";

const handleUserResponse = (data) => {
  window.localStorage.setItem(localStorageAccessKey, data.accessToken);
  return data;
};

export const getToken = () => {
  return window.localStorage.getItem(localStorageAccessKey);
};

export const login = async (token) => {
  return authLogin(token).then(handleUserResponse);
};

export const me = async (ep, token) => {
  return client(ep, null, token).then((res) => res);
};

export const logout = async () => {
  window.localStorage.removeItem(localStorageAccessKey);
  window.localStorage.removeItem(localStorageRefreshKey);
};

const authURL = process.env.REACT_APP_API_URL;
const client = async (endpoint, data, token) => {
  const config = {
    method: "POST",
    data: data ? data : undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    auth: {
      username: data?.username,
      password: data?.password,
    },
  };

  const req = axios(`${authURL}/${endpoint}`, config).then(async (response) => {
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });

  return req;
};
