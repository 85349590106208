import styled from "styled-components";

export const DashArea = ({ children, section }) => {
  return (
    <div>
      <Breadcrumb>{section}</Breadcrumb>
      {children}
    </div>
  );
};

const Breadcrumb = styled.p`
  font-size: 14px;
  color: #687b8c;
  margin-bottom: 25px;
  font-weight: 500;
`;
