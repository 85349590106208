const getSubtopicsAmountPerTopic = (projects = []) => {
  const weeksBetween = (d1, d2) => {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  };

  const subtopics = projects.map((project) =>
    project.subtopics.map((subtopic) => {
      return {
        name: subtopic.name,
        topic: subtopic.topic.name,
        presented: project.presented,
        voted: project.voted,
        status: project.status.name,
        average_weeks: weeksBetween(
          new Date(project.presented),
          new Date(project.voted)
        ),
      };
    })
  );

  const mergedSubTopics = [].concat.apply([], subtopics);

  const results = Object.values(
    mergedSubTopics.reduce((acc, subtopic) => {
      acc[subtopic.name] = acc[subtopic.name]
        ? { ...subtopic, count: 1 + acc[subtopic.name].count }
        : { ...subtopic, count: 1 };
      return acc;
    }, {})
  );

  const resultsByRejectedOrApprovedStatus = results.filter(
    (project) =>
      project.status === "Rejected" || project.status === "Passed/Approved"
  );

  const tree = Object.entries(
    results.reduce((acc, { name, topic, count }) => {
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push({ name, count });

      return acc;
    }, {})
  ).map(([name, children]) => ({ name, children }));

  return {
    results,
    resultsByRejectedOrApprovedStatus,
    tree,
  };
};

export { getSubtopicsAmountPerTopic };
