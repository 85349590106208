import { ProgressLabel } from "UI/molecules/ProgressLabel";
import { Container, EmptyBar, ProgressBar, Wrapper } from "./styles";

const Progress = ({ ...props }) => {
  const { count, total, color, bgColor, labelText, labelNumber } = props;

  const percentageCompleted = () => {
    return (count * 100) / total;
  };

  return (
    <Container>
      <ProgressLabel
        percentage={(percentageCompleted().toFixed(2)) + "%"}
        bgColor={bgColor}
        color={color}
        labelNumber={labelNumber}
      >
        {labelText}
      </ProgressLabel>
      <Wrapper>
        <EmptyBar color={bgColor}></EmptyBar>
        <ProgressBar
          width={(percentageCompleted().toFixed(2)) + "%"}
          color={color}
        ></ProgressBar>
      </Wrapper>
    </Container>
  );
};

export { Progress };
