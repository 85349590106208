import { useCommonLookups } from "queries/lookups";

const Countries = () => {
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <ul className="ajustes">
          {countries?.map((country) => (
            <li style={{ padding: "1rem" }}>
              <p>{country.name}</p>
              <div className="actions"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { Countries };
