import { Badge, Button, Input, Select } from "UI/atoms/index";
import { useCommonLookups } from "queries/lookups";
import { useEffect, useState } from "react";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { CloseModalIcon as CloseModal } from "shared/assets/svgs/CloseModal";
import { CloudArrowDown, OrderBy } from "shared/assets/svgs/DesktopPageIcons";
import { PencilIcon as Pencil } from "shared/assets/svgs/Pencil";
import { Modal } from "UI/molecules/index";
import { getSpanishDateFormat } from "utils/getSpanishDateFormat";
import { Formik } from "formik";
import { userSchemaValidator } from "./utils/schemaValidator";
import { useEditUser, useCreateUser } from "queries/users";
import { Error } from "UI/atoms/Error";
import { Spinner } from "shared/assets/svgs";
import { filterUsers } from "./utils/filterUsers";
import { usePaginator } from "hooks/usePaginator";
import { useCleanFilters } from "hooks/useCleanFilters";

function Usuarios() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});
  const { selects, setSelects, cleanFilters } = useCleanFilters();
  const { data: users, isLoading: isConsultantsLoading } =
    useCommonLookups("users");
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");

  const filteredUsers = filterUsers(users, selects);
  const isEdit = Object.keys(user).length > 0 ? true : false;

  const { items, pages, pagesList, setPageNumber } = usePaginator(
    filteredUsers,
    10
  );

  const onError = (err) => {
    setError(
      err?.response?.data?.email ? err?.response?.data?.email : "user created"
    );
  };

  const { mutate: add, isLoading: isAddLoading } = useCreateUser(onError);
  const {
    mutate: edit,
    isLoading: isEditLoading,
    isSuccess: isEditSuccess,
  } = useEditUser(user?.id);

  const initialValues = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    country: user?.country_id || "",
    role: user?.role || "",
    is_active: user?.is_active || "",
  };

  const handleOpenDetailUserModal = (user) => {
    setIsOpenModal(true);
    setUser(user);
  };

  const onSubmit = (data) => {
    if (isEdit) {
      const changedValues = Object.entries(data).reduce((acc, [key, value]) => {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
          acc[key] = value;
        }
        return acc;
      }, {});
      edit(changedValues);
    } else {
      add(data);
    }
  };

  const handleSelectChange = (e, key) => {
    setSelects((prevState) => ({
      ...prevState,
      [key]: key === "is_active" ? Boolean(e.target.value) : e.target.value,
    }));
    setPageNumber(1);
  };

  useEffect(() => {
    if (error === "user created" || isEditSuccess) {
      setIsOpenModal(false);
    } else {
      return;
    }
  }, [error, isEditSuccess]);

  return (
    <>
      <div className="usuarios-dashboard">
        <div className="dash-area">
          <p className="breadcrumb">Users</p>
          <ul className="dash-table-actions select-left-btn-right">
            <li>
              <Select
                name="pais"
                labelText={"Country"}
                className={"select-holder"}
                onChange={(e) => handleSelectChange(e, "country")}
              >
                <option disabled selected>
                  Select country
                </option>
                {countries?.map((country) => (
                  <option key={country.id} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </li>
            <li>
              <Select
                name="tipo"
                labelText={"Role"}
                className={"select-holder"}
                onChange={(e) => handleSelectChange(e, "role")}
              >
                <option disabled selected>
                  Select role
                </option>
                {["Administrators", "Coordinators", "Consultants"].map(
                  (role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  )
                )}
              </Select>
            </li>
            <li>
              <Select
                name="is_active"
                id="is_active"
                labelText={"Activity Status"}
                className={"select-holder"}
                onChange={(e) => handleSelectChange(e, "is_active")}
              >
                <option disabled selected>
                  Select activity status
                </option>
                {[
                  { value: "Yes", status: 1 },
                  { value: "No", status: "" },
                ].map((item) => (
                  <option key={item.value} value={item.status}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </li>
            <li style={{ display: "flex", justifyContent: "flex-end" }}>
              <Badge
                color="#687b8c"
                background="rgba(104, 123, 140, 0.1)"
                className="badge"
                padding="9px 15px"
                margin="0 0 0 0.5rem"
                onClick={cleanFilters}
              >
                Clean filters
              </Badge>
            </li>
            <li>
              <Button
                height="40px"
                width="126px"
                className="btn-with-icon"
                type="button"
                onClick={() => setIsOpenModal(true)}
              >
                <CloudArrowDown /> <span>New user</span>
              </Button>
            </li>
          </ul>
          <table className="dash-table">
            <thead>
              <tr>
                <th className="nowrap">
                  <span className="label">DATE REGISTERED</span>
                </th>
                <th className="left">NAME</th>
                <th className="nowrap">
                  <span className="label">ROLE</span>
                </th>
                <th className="nowrap">
                  <span className="label">COUNTRY</span>
                </th>
                <th className="nowrap">
                  <span className="label">ACTIVITY STATUS</span>
                </th>
                <th className="left">EMAIL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items?.map((user) => (
                <tr>
                  <td className="nowrap center">
                    {getSpanishDateFormat(user.date_joined)}
                  </td>
                  <td>
                    <strong>
                      {user.first_name} {user.last_name}
                    </strong>
                  </td>
                  <td className="nowrap center">{user.role}</td>
                  <td className="nowrap center">{user.country}</td>
                  {user.is_active ? (
                    <td className="nowrap center">
                      <Badge
                        background="#E4F6EE"
                        color="#2EC07F"
                        className="badge estado"
                      >
                        Activo
                      </Badge>
                    </td>
                  ) : (
                    <td className="nowrap center">
                      <Badge
                        background="#FFEFEA"
                        color="#E00000"
                        className="badge estado"
                      >
                        Archivado
                      </Badge>
                    </td>
                  )}
                  <td className="nowrap">{user.email}</td>
                  <td>
                    <Button
                      height="30px"
                      width="30px"
                      className="circle-btn"
                      onClick={() => handleOpenDetailUserModal(user)}
                    >
                      <Pencil />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {pagesList.map((lof) => (
              <Badge
                key={lof}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => setPageNumber(lof)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                {lof}
              </Badge>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="530px"
        padding="34px 26px 34px 40px"
        className="usuario-ficha"
      >
        <div className="ficha-titlebar">
          <h2>User</h2>
          <Button
            background="white"
            width="32px"
            height="32px"
            onClick={() => setIsOpenModal(false)}
          >
            <CloseModal />
          </Button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={userSchemaValidator(isEdit)}
          onSubmit={(values) => onSubmit(values)}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="dash-area">
              <div className="field-row">
                <label htmlFor="nombre" className="form-label">
                  First name
                </label>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  height="46px"
                  className="form-control"
                  onChange={(name) => {
                    formik.setFieldValue("first_name", name.target.value);
                  }}
                  value={
                    formik.values.first_name
                      ? formik.values.first_name
                      : user?.first_name
                  }
                />
                {formik.errors.first_name && formik.touched.first_name ? (
                  <Error>{formik.errors.first_name}</Error>
                ) : null}
              </div>
              <div className="field-row">
                <label htmlFor="nombre" className="form-label">
                  Last name
                </label>
                <Input
                  type="text"
                  name="last_name"
                  id="last_name"
                  height="46px"
                  className="form-control"
                  onChange={(last_name) => {
                    formik.setFieldValue("last_name", last_name.target.value);
                  }}
                  value={
                    formik.values.last_name
                      ? formik.values.last_name
                      : user?.last_name
                  }
                />
                {formik.errors.last_name && formik.touched.last_name ? (
                  <Error>{formik.errors.last_name}</Error>
                ) : null}
              </div>
              <div className="field-row">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  height="46px"
                  className="form-control"
                  onChange={(email) => {
                    formik.setFieldValue("email", email.target.value);
                  }}
                  value={
                    formik.values.email ? formik.values.email : user?.email
                  }
                />
                {formik.errors.email && formik.touched.email ? (
                  <Error>{formik.errors.email}</Error>
                ) : null}
              </div>
              <div className="field-row">
                <ul className="field-colums">
                  <li>
                    <Select
                      name="country"
                      id="country"
                      width="200px"
                      labelText={"Country"}
                      labelClassName={"form-label"}
                      labelHtmlFor={"pais"}
                      onChange={(country) => {
                        formik.setFieldValue(
                          "country",
                          parseInt(country.target.value)
                        );
                      }}
                      value={
                        formik.values.country
                          ? formik.values.country
                          : user?.country_id
                      }
                    >
                      <option disabled selected={!isEdit ? true : false}>
                        Select country
                      </option>
                      {countries?.map((country) => (
                        <option
                          key={country.id}
                          value={country.id}
                          selected={user?.country_id === country.id}
                        >
                          {country.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.country && formik.touched.country ? (
                      <Error>{formik.errors.country}</Error>
                    ) : null}
                  </li>
                  <li>
                    <Select
                      name="is_active"
                      id="is_active"
                      width="200px"
                      labelText={"Activity Status"}
                      labelClassName={"form-label"}
                      labelHtmlFor={"entidad"}
                      onChange={(isActive) => {
                        formik.setFieldValue(
                          "is_active",
                          Boolean(isActive.target.value)
                        );
                      }}
                      value={
                        formik.values.is_active
                          ? formik.values.is_active
                          : user?.is_active
                      }
                    >
                      <option
                        value=""
                        disabled
                        selected={!isEdit ? true : false}
                      >
                        Select activity status
                      </option>
                      {[
                        { value: "Yes", status: "true" },
                        { value: "No", status: "" },
                      ].map((item) => (
                        <option
                          key={item.value}
                          value={item.status}
                          selected={user?.is_active === Boolean(item.status)}
                        >
                          {item.value}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.is_active && formik.touched.is_active ? (
                      <Error>{formik.errors.is_active}</Error>
                    ) : null}
                  </li>
                </ul>
              </div>
              <div className="field-row">
                <ul className="field-colums">
                  <li>
                    <Select
                      name="role"
                      id="role"
                      width="200px"
                      labelText={"Role"}
                      labelClassName={"form-label"}
                      labelHtmlFor={"rol"}
                      onChange={(role) => {
                        formik.setFieldValue("role", role.target.value);
                      }}
                      value={
                        formik.values.role ? formik.values.role : user?.role
                      }
                    >
                      <option
                        value=""
                        disabled
                        selected={!isEdit ? true : false}
                      >
                        Select role
                      </option>
                      {["Administrators", "Coordinators", "Consultants"].map(
                        (role) => (
                          <option
                            key={role}
                            value={role}
                            selected={user?.role === role}
                          >
                            {role}
                          </option>
                        )
                      )}
                    </Select>
                    {formik.errors.role && formik.touched.role ? (
                      <Error>{formik.errors.role}</Error>
                    ) : null}
                  </li>
                </ul>
              </div>
              <div className="actions-row actions-row-left">
                <Button
                  width="124px"
                  background="#F0F4F7"
                  color="#344854"
                  className="btn-with-icon"
                  onClick={() => (setIsOpenModal(false), setUser({}))}
                >
                  Cancel
                </Button>
                <Button
                  width="124px"
                  className="btn-with-icon white-icon"
                  type="submit"
                  disabled={!formik.dirty}
                >
                  {isAddLoading || isEditLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <Check /> <span>Save</span>
                    </>
                  )}
                </Button>
              </div>
              <Error>{error !== "user created" && error}</Error>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export { Usuarios };
