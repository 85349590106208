import React, { useState } from "react";
import { Modal } from "UI/atoms/index";

import { Button } from "UI/atoms/index";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { useDeleteAttachmentFromProject } from "queries/projects";

const DeleteModal = ({
  setIsOpenDeleteModal,
  isDeleteModalOpen,
  file,
  projectId,
  setFieldValue,
  attachments,
}) => {
  const { mutate: deleteAttachment } = useDeleteAttachmentFromProject(
    file?.id,
    projectId
  );

  const handleDeleteAttachment = () => {
    const isServerSavedAttachment = Boolean(file.id);
    if (isServerSavedAttachment) {
      deleteAttachment();
      const filteredFiles = attachments?.filter(
        (uploadedFile) => uploadedFile.id !== file.id
      );
      setFieldValue("attachments", filteredFiles);
    } else {
      const filteredFiles = attachments?.filter(
        (uploadedFile) => uploadedFile.file.name !== file.file.name
      );
      setFieldValue("attachments", filteredFiles);
    }

    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        toggleModal={isDeleteModalOpen}
        width="500px"
        padding="2rem"
        className="fecha-aprobado"
      >
        <h4 style={{ lineHeight: "1.5" }}>
          Warning! You will delete the file {file.name}
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            width="133px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => setIsOpenDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            width="133px"
            background="red"
            className="btn-with-icon white-icon"
            onClick={() => handleDeleteAttachment()}
          >
            <Check /> <span>Confirm</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { DeleteModal };
