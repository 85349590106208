import React from "react";
import styled from "styled-components";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#F17A36", "#6C3F98", "#0379F2", "#976AD1", "#FF5FBF"];

const CustomizedContent = (props) => {
  const { root, depth, x, y, width, height, index, name, value } = props;

  const maxCharsPerLine = Math.floor(width / 7); // ajusta el número según sea necesario
  const maxLines = 2;

  const ellipsis = "...";

  const linesName = [];
  let lineName = "";
  const wordsName = !!name && name.split(" ");

  if (Array.isArray(wordsName)) {
    wordsName.forEach((word, i) => {
      if (lineName.length + word.length <= maxCharsPerLine) {
        lineName += word + " ";
      } else {
        linesName.push(lineName.trim());
        lineName = word + " ";
      }
      if (i === wordsName.length - 1) {
        linesName.push(lineName.trim());
      }
    });
  }

  const linesSector = [];
  let lineSector = "";
  const wordsSector = !!name && name.split(" ");

  if (Array.isArray(wordsSector)) {
    wordsSector.forEach((word, i) => {
      if (lineSector.length + word.length <= maxCharsPerLine) {
        lineSector += word + " ";
      } else {
        linesSector.push(lineSector.trim());
        lineSector = word + " ";
      }
      if (i === wordsSector.length - 1) {
        linesSector.push(lineSector.trim());
      }
    });
  }

  const lineHeight = 15; // ajusta el número según sea necesario
  const textY =
    y + height / 2 + (linesSector.length * lineHeight) / 2 - 2.5 - 2;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill:
            depth < 2
              ? COLORS[Math.floor((index / root?.children?.length) * 6)]
              : "none",
          stroke: "#fff",
          strokeWidth: 1 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + 15}
          y={y + 27}
          fill="#fff"
          fontSize={14}
          className="barlow-medium"
        >
          {linesName.slice(0, maxLines).map((line, i) => (
            <tspan
              key={`name-${i}`}
              x={x + 15}
              dy={i > 0 ? lineHeight : 0}
              style={{
                overflow: "hidden",
                textOverflow:
                  linesName.length > maxLines && i === maxLines - 1
                    ? "ellipsis"
                    : "clip",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: maxLines,
              }}
            >
              {line}
            </tspan>
          ))}
        </text>
      ) : null}
      {depth === 2
        ? linesSector.slice(0, maxLines).map((line, i) => (
            <text
              key={`sector-${i}`}
              x={x + width / 2}
              y={textY + i * lineHeight}
              textAnchor="middle"
              fill="#fff"
              fontSize={12}
              className="barlow-medium "
            >
              <tspan
                style={{
                  overflow: "hidden",
                  textOverflow:
                    linesName.length > maxLines && i === maxLines - 1
                      ? "ellipsis"
                      : "clip",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: maxLines,
                }}
              >
                {line}
              </tspan>
            </text>
          ))
        : null}
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <ToolTip>
        <ToolTipTitle>{`${payload[0].payload.name}: ${payload[0].payload.count}`}</ToolTipTitle>
        <p>{payload[0].payload.root.name}</p>
      </ToolTip>
    );
  }

  return null;
};

const TreeMap = ({ tree }) => (
  <div style={{ marginTop: 20 }}>
    <ResponsiveContainer width="100%" height={225}>
      <Treemap
        data={tree}
        dataKey="count"
        stroke="#fff"
        fill="#8884d8"
        content={<CustomizedContent />}
        isAnimationActive={false}
        // animationEasing="ease-out"
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  </div>
);

const ToolTip = styled.div`
  width: 200px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
`;

const ToolTipTitle = styled.p`
  font-size: 14px;
`;

export default TreeMap;
