import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

import { Button, Input } from "UI/atoms";
import { Check, Trash } from "shared/assets/svgs";

import {
  useAddTopicWithSubtopics,
  useEditSubtopic,
  useEditTopic,
  useAddSubtopic,
  useGetSubtopicByTopicId,
} from "queries/lookups";
import { DeleteModal } from "pages/Settings/UI/organisms/DeleteModal";

const AddEditTopic = ({
  setOpenAddOrEditModal,
  actionText,
  method,
  topic,
  keyName,
  name,
}) => {
  const { data: subtopics, isLoading: isSubtopicsLoading } =
    useGetSubtopicByTopicId(topic?.id);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [subtopicsByTopicId, setSubtopicsByTopicId] = useState([]);
  const [subtopic, setSubtopic] = useState([]);
  const [removedSubtopics, setRemovedSubtopics] = useState([]);
  const { mutate: editTopic } = useEditTopic(topic?.id);
  const { mutate: addTopicWithSubtopics } = useAddTopicWithSubtopics("topics");
  const { mutate: editSubtopic } = useEditSubtopic(topic?.id);
  const { mutate: addSubtopic } = useAddSubtopic(topic?.id);

  const onSubmit = (values) => {
    // puede parecer raro q no haya logica para manejar cuándo ejecutar X método dependiendo de la acción del usuario,
    // pero para eso tenemos la propiedad enabled: true en react-query q permite ejecutar queries si y solo si
    // existe el dato que a nosotres nos importe.
    if (method === "PUT") {
      const performChainedSideEffects = values.subtopics
        ?.filter((subtopic) => subtopic)
        .forEach((subtopic) => {
          if (subtopic.isNew) {
            addSubtopic(subtopic);
          } else {
            const data = {
              name: subtopic.name,
              subtopicId: subtopic.id,
              topic: topic.id,
            };
            editSubtopic({ ...data });
          }
        });
      editTopic(values.topic);
      setOpenAddOrEditModal("");
      return performChainedSideEffects;
    }
    if (method === "POST") {
      const valuesCopy = { ...values };

      const topicWithSubtopics = {
        name: values.topic.name,
        // mandar el array de subtopics en el body si y solo si existen subtopics agregados.
        // el backend me pide sí o sí que le pase un subtopic, aunque sea un array vacío.
        ...(!!valuesCopy.subtopics
          ? { subtopics: [...valuesCopy.subtopics] }
          : { subtopics: [] }),
      };
      addTopicWithSubtopics(topicWithSubtopics);
      setOpenAddOrEditModal("");
    }
  };

  const handleAddSubtopicAccordingToMethod = () => {
    if (method === "PUT") {
      setSubtopicsByTopicId([
        ...subtopicsByTopicId,
        {
          name: "",
          isNew: true,
          topic: { name: topic?.topic?.name, id: topic?.topic?.id },
        },
      ]);
    }
    if (method === "POST") {
      setSubtopicsByTopicId([...subtopicsByTopicId, { name: "" }]);
    }
  };

  const handleDeleteLookup = (subtopic) => {
    setIsDeleteModalOpen(true);
    setSubtopic(subtopic);
  };

  const removeAddedSubtopics = (index) => {
    const deleteAddedSubtopics = subtopicsByTopicId.filter(
      (rs, idx) => idx !== index
    );
    setSubtopicsByTopicId(deleteAddedSubtopics);
  };
  return (
    <>
      <Formik initialValues={{}} onSubmit={(values) => onSubmit(values)}>
        {({ setFieldValue }) => (
          <Form className="dash-area">
            <FieldArray name="subtopics">
              {({ insert, remove, push }) => (
                <div className="field-row">
                  <div style={{ margin: "1rem 0" }}>
                    <h4>
                      {actionText} {topic?.name} topic
                    </h4>
                    <div className="field-with-icon">
                      <Input
                        type="text"
                        name="topic"
                        height="46px"
                        className="form-control"
                        placeholder="Topic"
                        defaultValue={topic?.name}
                        onChange={(e) => {
                          setFieldValue("topic", {
                            ...topic?.topic,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {subtopics.length > 0 ? (
                    <div style={{ margin: "1rem 0" }}>
                      <h4>{actionText} subtopics</h4>
                      <ul
                        className="asignaciones"
                        style={{ height: "fit-content" }}
                      >
                        {subtopics?.map((subtopic, index) => (
                          <li key={index}>
                            <div
                              className="item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              justifyContent="space-between"
                            >
                              <Input
                                type="text"
                                name={`subtopics.${index}`}
                                height="46px"
                                className="form-control"
                                margin={"0 1rem 0 0"}
                                defaultValue={subtopic?.name}
                                onChange={(e) => {
                                  setFieldValue(`subtopics.${index}`, {
                                    ...subtopic,
                                    name: e.target.value,
                                  });
                                }}
                              />
                              <Button
                                width="30px"
                                height="30px"
                                background="#FF562F"
                                className="circle-btn"
                                type="button"
                                onClick={() => handleDeleteLookup(subtopic)}
                              >
                                <Trash />
                              </Button>
                            </div>
                          </li>
                        ))}
                        {/* {errors === 'subtopic-already-selected' ? <span style={{color: 'red', fontSize: '12px'}}>Subtopic has already been added</span> : null} */}
                      </ul>
                    </div>
                  ) : null}
                  <div
                    style={{
                      margin: "1rem 0",
                      maxHeight: "40vh",
                      overflowY: "scroll",
                    }}
                  >
                    <ul className="">
                      {subtopicsByTopicId?.map((subtopic, index) => (
                        <li key={index} style={{ margin: "1rem 0" }}>
                          <div
                            className="item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            justifyContent="space-between"
                          >
                            <Input
                              type="text"
                              name={`subtopics.${index}`}
                              height="46px"
                              className="form-control"
                              margin={"0 1rem 0 0"}
                              defaultValue={subtopic?.name}
                              onChange={(e) => {
                                setFieldValue(`subtopics.${index}`, {
                                  ...subtopic,
                                  name: e.target.value,
                                });
                              }}
                            />
                            <Button
                              width="30px"
                              height="30px"
                              background="#FF562F"
                              className="circle-btn"
                              type="button"
                              onClick={() => removeAddedSubtopics(index)}
                            >
                              <Trash />
                            </Button>
                          </div>
                        </li>
                      ))}
                      {/* {errors === 'subtopic-already-selected' ? <span style={{color: 'red', fontSize: '12px'}}>Subtopic has already been added</span> : null} */}
                    </ul>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      background="white"
                      color="#344854"
                      fontSize="xs"
                      className="btn-with-icon"
                      type="button"
                      onClick={() => handleAddSubtopicAccordingToMethod()}
                    >
                      + Add subtopic
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
            <div className="actions-row">
              <Button
                width="133px"
                background="#F0F4F7"
                color="#344854"
                className="btn-with-icon"
                type="button"
                onClick={() => setOpenAddOrEditModal("")}
              >
                Cancel
              </Button>
              <Button
                width="133px"
                className="btn-with-icon white-icon"
                type="submit"
              >
                <Check /> <span>Confirm</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {isDeleteModalOpen ? (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsOpenDeleteModal={setIsDeleteModalOpen}
          lookup={subtopic}
          name="subtopics"
        />
      ) : null}
    </>
  );
};

export { AddEditTopic };
