import Mapa from "shared/images/sudamerica.svg";

export const Map = ({ mapData }) => {
  return (
    <div className="map">
      <div className="map-holder">
        <img src={Mapa} width="389" />
        {mapData.map((data) => (
          <div className={"circle " + data.className}>
            <span>{data.count}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
