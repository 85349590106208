import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  Asignation,
  Attachments,
  Information,
  Parameters,
  TabsLayout,
  Topics,
} from "./UI/templates";
import { Tab } from "./UI/organisms/Tab";

import { Formik } from "formik";
import { useCommonLookups } from "queries/lookups";
import {
  useAddAttachmentToProject,
  useCreateLawProject,
  useEditLawProject,
  useGetProjectById,
} from "queries/projects";
import { useParams } from "react-router-dom";
import { Spinner } from "shared/assets/svgs";

import {
  addProjectInitialValues,
  editProjectInitialValues,
} from "./utils/initialValues";
import { getUser } from "utils/getUser";
import { formSchemaValidator } from "./utils/schemaValidator";
import { addFormSchemaValidator } from "./utils/schemaValidator";

const layoutsTabs = {
  information: <Information />,
  parameters: <Parameters />,
  attachments: <Attachments />,
  topics: <Topics />,
  asignation: <Asignation />,
};

const tabs = (isConsultant) => {
  return [
    {
      tab: "information",
      title: "Information",
    },
    {
      tab: "parameters",
      title: "Parameters",
    },
    {
      tab: "attachments",
      title: "Attachments",
    },
    {
      tab: "topics",
      title: "Topics",
    },
    !isConsultant
      ? {
          tab: "asignation",
          title: "Assignation",
        }
      : null,
  ].filter((tab) => tab);
};

function AddEditProject() {
  const [successResponse, setSuccessResponse] = useState();
  const [responseError, setResponseError] = useState();

  const { id } = useParams();
  const isEdit = id ? true : false;

  const user = getUser();

  // this will be executed only if there is an id to fetch something.
  // check react-query docs for dependent-queries and enabled status:
  //https://tanstack.com/query/v4/docs/guides/dependent-queries?from=reactQueryV3&original=https://react-query-v3.tanstack.com/guides/dependent-queries
  const { data: project, isLoading: isProjectLoading } = useGetProjectById(id);

  const [selectedTab, setSelectedTab] = useState("information");

  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("subtopics");
  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project_status");
  const { data: probabilities, isLoading: isProbabilitiesLoading } =
    useCommonLookups("probability_levels");
  const { data: impacts, isLoading: isImpactsLoading } =
    useCommonLookups("impact_levels");
  const { data: entities, isLoading: isEntitiesLoading } =
    useCommonLookups("entities");
  const { data: successTypes, isLoading: isSuccessLoading } =
    useCommonLookups("success_levels");

  const { mutate: createLawProject, isSuccess: isCreateLawProjectSuccess } =
    useCreateLawProject();
  const { mutate: editLawProject, isSuccess: isEditLawProjectSuccess } =
    useEditLawProject(id);
  const { mutate: addAttachmentToProject } = useAddAttachmentToProject();

  const lookups = {
    countries: [...countries],
    topics: [...topics],
    statuses: [...statuses],
    probabilities: [...probabilities],
    impacts: [...impacts],
    entities: [...entities],
    successTypes: [...successTypes],
  };

  const handleSelectedTab = (tab) => {
    return selectedTab === tab && "selected";
  };

  const isConsultant = user?.role === "Consultants";
  const filteredTabs = tabs(isConsultant);
  const lastTabIndex = filteredTabs.length - 1;
  const lastTab = filteredTabs[lastTabIndex];
  const isLastTab = lastTab.tab === selectedTab;

  const props = {
    lookups,
    project,
    projectId: id,
  };
  const handleLayoutsTabsWithExtendedProps = (formik) => {
    return React.cloneElement(layoutsTabs[selectedTab], {
      ...props,
      formik,
      responseError,
      successResponse,
      isEdit,
      setSelectedTab,
      isLastTab,
      isCreateLawProjectSuccess,
      isEditLawProjectSuccess,
    });
  };

  // Normalizar los nombres de archivo
  const normalizeFileName = (fileName) => {
    return fileName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  };

  // Lógica común para la solicitud de creación y edición de proyectos
  const handleSuccess = (data, isEdit, reset, attachments) => {
    !isEdit && reset();
    setSuccessResponse(true);
    attachments.forEach((attachment) => {
      addAttachmentToProject({
        file: attachment.file,
        type: attachment.file.type,
        name: normalizeFileName(attachment.file.name),
        id: data.id,
      });
    });
  };

  const onSubmit = (values, resetForm) => {
    const { presented, attachments, voted, subtopics, consultant } = values;

    // Filtrar los archivos que no tengan un ID asignado
    const attachmentsIfProjectIsEdited = attachments?.filter(
      (file) => !file.id
    );

    // Asignar ID de usuario o IDs de consultor al proyecto
    const consultantPayload = isConsultant
      ? [user.user_id]
      : consultant?.map((consul) => consul.id);

    // Asignar IDs de subtemas al proyecto
    const subtopicsIds = subtopics?.map((subtopic) => subtopic.id);

    // Dar formato a las fechas de presentación y votación, si están presentes
    const presentedDate = presented
      ? new Date(presented).toISOString().split("T")[0].replace(/-/g, "-")
      : null;
    const votedDate = voted
      ? new Date(voted).toISOString().split("T")[0].replace(/-/g, "-")
      : null;

    // Solicitar la creación de un nuevo proyecto
    if (!isEdit) {
      const newData = {
        ...values,
        consultant: consultantPayload,
        presented: presentedDate,
        subtopics: subtopicsIds,
        voted: votedDate,
      };

      createLawProject(newData, {
        onSuccess: (data) =>
          handleSuccess(data, isEdit, resetForm, newData.attachments),
        onError: (err) => setResponseError(err?.response?.status),
      });
    }
    // Solicitar la edición de un proyecto existente
    else {
      const updatedData = {
        ...values,
        consultant: consultantPayload,
        subtopics: subtopicsIds,
        presented: presentedDate,
        voted: votedDate,
      };

      editLawProject(updatedData, {
        onSuccess: (data) =>
          handleSuccess(data, isEdit, resetForm, attachmentsIfProjectIsEdited),
        onError: (err) => setResponseError(err?.response?.status),
      });
    }
  };

  const handleInitialValues = () => {
    if (isEdit) {
      return editProjectInitialValues(project, isConsultant, user.user_id);
    } else {
      return addProjectInitialValues(isConsultant, user.user_id);
    }
  };

  if (
    isCountriesLoading ||
    isTopicsLoading ||
    isStatusesLoading ||
    isProbabilitiesLoading ||
    isImpactsLoading ||
    isEntitiesLoading
  ) {
    return <Spinner />;
  }

  if (isEdit && isProjectLoading) {
    return <Spinner />;
  }

  return (
    <div className="politicas-nuevo">
      <Formik
        initialValues={handleInitialValues()}
        validationSchema={formSchemaValidator}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="dash-area">
            <p className="breadcrumb">
              <strong>Law projects</strong> <span className="pipe">/</span>
              {isEdit ? project.name : "New project"}
            </p>
            <div className="tabs-card">
              <ul className="tab-menu">
                {filteredTabs.map((t) => (
                  <Tab
                    key={t.tab}
                    tab={t.tab}
                    title={t.title}
                    onSetSelectedTab={setSelectedTab}
                    onHandleSelectedTab={handleSelectedTab}
                  />
                ))}
              </ul>
              <TabsLayout>
                {handleLayoutsTabsWithExtendedProps(formik)}
              </TabsLayout>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export { AddEditProject };
