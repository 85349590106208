import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Error, Modal } from "UI/atoms/index";
import axios from "axios";
import { Badge, Button, Input, ListItem } from "UI/atoms/index";
import { CloudArrowDown } from "shared/assets/svgs/DesktopPageIcons";
import { Trash } from "shared/assets/svgs";

import { Pencil, Spinner } from "shared/assets/svgs";
import { CloseModalIcon as CloseModal } from "shared/assets/svgs/CloseModal";
import { LinkIcon } from "shared/assets/svgs/Link";
import { WarningModal } from "UI/molecules/WarningModal";
import { CalendarIcon as Calendar } from "shared/assets/svgs/Calendar";
import { CheckIcon as Check } from "shared/assets/svgs/Check";

import {
  useDeleteProject,
  useEditLawProject,
  useGetProjectById,
} from "queries/projects";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_KEY = process.env.REACT_APP_API_URL;

const ProjectDetail = ({ projectId, setIsOpenModal, colors }) => {
  const [isWarningModalOpened, setIsOpenWarningModal] = useState(false);
  const { data: project, isLoading: isProjectLoading } =
    useGetProjectById(projectId);

  const [isOpenApprovalDateModal, setIsOpenApprovalDateModal] = useState(false);
  const [isOpenAttachmentModal, setIsOpenAttachmentModal] = useState(false);
  const [votedDate, setVotedDate] = useState();
  const hasAttachments = project.attachments?.length > 0;

  const { mutate: deleteProject, isLoading: isDeleteProjectLoading } =
    useDeleteProject();

  const {
    mutate: editLawProject,
    isLoading: isEditVotedDateLoading,
    isSuccess: isEditVotedSuccess,
  } = useEditLawProject(project.id);

  const handleColors = (projectLookupColorId) => {
    return colors.find((color) => color.id === projectLookupColorId).code;
  };

  const handleEditVotedDate = () => {
    editLawProject(
      {
        voted: votedDate
          ? new Date(votedDate).toISOString().split("T")[0].replace(/-/g, "-")
          : null,
      },
      {
        onSuccess: () => setIsOpenApprovalDateModal(false),
      }
    );
  };

  const handleOpenWarningModal = () => {
    setIsOpenWarningModal(true);
  };

  const handleOpenAttachmentsModal = () => {
    setIsOpenAttachmentModal(true);
  };

  const handleApprovalDateModal = () => {
    setIsOpenApprovalDateModal(false);
    setVotedDate(null);
  };

  const handleDownload = (file) => {
    const url = `${API_KEY}/law_projects/attachment/${file.id}/`;
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = file.name;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data]));
        link.click();
      });
  };

  useEffect(() => {
    if (Boolean(project.voted)) {
      setVotedDate(new Date(`${project.voted} EDT`));
    } else {
      setVotedDate(null);
    }
  }, [project]);

  if (isProjectLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="ficha-topbar">
        <ul>
          <Link to={`/edit-project/${project.id}`}>
            <Button width="110px" className="btn-with-icon">
              <Pencil /> <span>Edit</span>
            </Button>
          </Link>
          <li>
            <Button
              width="150px"
              background="#EBF2FF"
              color="#0379F2"
              className="btn-with-icon"
              onClick={() => setIsOpenApprovalDateModal(true)}
            >
              <Check /> <span>Voted date</span>
            </Button>
          </li>
          <li>
            <Button
              type="button"
              width="150px"
              background="#FF562F"
              className="btn-with-icon"
              onClick={() => handleOpenWarningModal()}
            >
              <Trash /> <span>Delete project</span>
            </Button>
          </li>
        </ul>

        <Button
          background="white"
          width="32px"
          height="32px"
          onClick={() => setIsOpenModal(false)}
        >
          <CloseModal />
        </Button>
      </div>
      <div className="ficha-head">
        <h2>{project.name}</h2>
        <p>
          Assigned to {project.consultant[0].first_name}{" "}
          {project.consultant[0].last_name} el 10/02/2022
        </p>
      </div>
      <ul className="ficha-info">
        <li>
          <Badge
            background={handleColors(project.status.color)}
            className="badge estado"
          >
            {project.status.name}
          </Badge>
        </li>
        <li>{project.legal_id}</li>
        <li>{project.subtopics[0].name}</li>
        <li>{project.location.name}</li>
        <li>{project.entity.name}</li>
      </ul>
      <ul className="ficha-data">
        <li>
          <label>PRESENTED ON</label>
          <span className="text">{project.presented}</span>
        </li>
        <li>
          <label>VOTED ON</label>
          <span className="text">{project.voted ? project.voted : "N/A"}</span>
        </li>
        <li>
          <label>PROBABILITY</label>
          <Badge
            background={handleColors(project.probability.color)}
            className="badge"
          >
            {project.probability.name}
          </Badge>
        </li>
        <li>
          <label>IMPACT</label>
          <Badge
            background={handleColors(project.impact.color)}
            className="badge"
          >
            {project.impact.name}
          </Badge>
        </li>
        <li>
          <label>STRATEGY</label>
          {project.strategy_links.length >= 1 ? (
            <Button
              width="34px"
              height="34px"
              background="#EBF2FF"
              className="circle-btn"
            >
              <a
                href={project.strategy_links[0].url}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon />
              </a>
            </Button>
          ) : (
            <p style={{ padding: "9px 0" }}>No strategy link added</p>
          )}
        </li>
      </ul>
      <div className="ficha-body">
        <h4>DESCRIPTION</h4>
        <p>{project.description}</p>
      </div>
      <ul className="ficha-foot">
        <li>
          <label>LINK Nº 2</label>
          {project.strategy_links.length >= 2 ? (
            <Button
              width="34px"
              height="34px"
              background="#EBF2FF"
              className="circle-btn"
            >
              <a
                href={project.strategy_links[1].url}
                target="_blank"
                rel="noreferrer"
              >
                <LinkIcon />
              </a>
            </Button>
          ) : (
            <p style={{ padding: "9px 0" }}>No second link added</p>
          )}
        </li>
        <li>
          <label>DID THE STRATEGY WORK?</label>
          <Badge
            background="transparent"
            color="#1c2a34"
            fontSize="sm"
            fontWeight="400"
            className="badge"
            style={{ cursor: "auto" }}
          >
            {project.success.name}
          </Badge>
        </li>
        {hasAttachments ? (
          <li>
            <label>ATTACHMENTS</label>
            <Button
              width="34px"
              height="34px"
              background="#EBF2FF"
              className="circle-btn"
              onClick={() => handleOpenAttachmentsModal()}
            >
              <LinkIcon />
            </Button>
          </li>
        ) : null}
      </ul>
      <Modal
        isOpen={isOpenApprovalDateModal}
        toggleModal={setIsOpenApprovalDateModal}
        width="342px"
        padding="40px 32px"
        className="fecha-aprobado"
      >
        <h4>Date of Approval</h4>
        <div className="field-row">
          <div className="field-with-icon">
            <DatePicker
              name="voted"
              height="46px"
              className="react-datepicker__input-field"
              placeholder="DD/MM/AAAA"
              selected={votedDate}
              onChange={(val) => setVotedDate(val)}
              isClearable
            />
          </div>
        </div>
        <div className="actions-row">
          <Button
            width="133px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => handleApprovalDateModal()}
          >
            Cancel
          </Button>
          <Button
            width="133px"
            className="btn-with-icon white-icon"
            onClick={() => handleEditVotedDate()}
          >
            {isEditVotedDateLoading ? (
              <Spinner />
            ) : (
              <>
                <Check /> <span>Confirm</span>
              </>
            )}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenAttachmentModal}
        toggleModal={setIsOpenAttachmentModal}
        width="640px"
        padding="40px 32px"
        className="fecha-aprobado"
      >
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <h4>Attachments</h4>
          {project.attachments?.map((attachment, idx) => (
            <div
              key={attachment.id}
              style={{
                background: "#F0F4F7",
                borderRadius: "10px",
              }}
            >
              <ListItem
                className="item"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px 0",
                  padding: "10px",
                }}
              >
                {/* if has id it means that the file already exists in the server so we're editing an existing project */}
                <p>{attachment.name}</p>
                <Button
                  type="button"
                  height="30px"
                  width="30px"
                  background="blue"
                  className="circle-btn"
                  onClick={() => handleDownload(attachment)}
                >
                  <CloudArrowDown />
                </Button>
              </ListItem>
            </div>
          ))}
        </div>
      </Modal>
      <WarningModal
        setIsOpenWarningModal={setIsOpenWarningModal}
        isWarningModalOpened={isWarningModalOpened}
        mutation={deleteProject}
        id={project.id}
        isLoading={isDeleteProjectLoading}
        onSetIsModalOpen={setIsOpenModal}
        message="Warning! You will delete this project. Are you sure you want to continue?"
      />
    </>
  );
};

export { ProjectDetail };
