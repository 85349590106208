import { Map } from "UI/molecules/Map";
import { Progress } from "UI/molecules/index";
import { Card } from "UI/organisms";
import { DashArea } from "UI/organisms/DashArea";
import { useColors } from "queries/lookups";
import { Spinner } from "shared/assets/svgs";
import { getProjectsAmountPerStatus } from "utils/getProjectsAmountPerStatus";
import { getProjectsAmountPerTopic } from "../../utils/getProjectsAmountPerTopic";
import { TOPIC_COLOR_TYPES } from "./types/index";
import { getProjectsAmountPerCountry } from "./utils/getProjectsAmountPerCountry";

function Escritorio({ projects }) {
  const projectsAmountPerCountry = getProjectsAmountPerCountry(projects);
  const { total, results } = getProjectsAmountPerTopic(projects);

  const { data: colors, isLoading } = useColors();
  const { total: totalProjectsByAllStatus, results: projectsByStatus } =
    getProjectsAmountPerStatus(projects);

  const handleColors = (projectLookupColorId) => {
    return colors?.find((color) => color.id === projectLookupColorId).code;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="escritorio-dashboard">
      <DashArea section={"Desktop"}>
        <div className="escritorio">
          <div className="date">
            {/* <Select
                  className={"select-holder"}
                  labelText={"Year"}
                  name="year"
                >
                  {["2022", "2021", "2020", "2019"].map((item) => (
                    <option key={item} value={item} defaultValue="Select UI">
                      {item}
                    </option>
                  ))}
                </Select> */}
          </div>
          <Map mapData={projectsAmountPerCountry} />
          <div className="ranges">
            <Card>
              {projectsByStatus?.map((project) => (
                <Progress
                  count={project.count}
                  total={totalProjectsByAllStatus}
                  color={handleColors(project.color)}
                  bgColor="#EBF2FF"
                  labelText={project.status}
                  labelNumber={project.count}
                />
              ))}
            </Card>
            <Card>
              {results.map((topic) => (
                <Progress
                  count={topic.count}
                  total={total}
                  color={TOPIC_COLOR_TYPES.color.default}
                  bgColor={TOPIC_COLOR_TYPES.background.default}
                  labelText={topic.name}
                  labelNumber={topic.count}
                />
              ))}
            </Card>
          </div>
        </div>
      </DashArea>
    </div>
  );
}

export { Escritorio };
