import React, { useState } from "react";
import { useCommonLookups } from "queries/lookups";
import { Button } from "UI/atoms";
import { Plus, Pencil, Trash } from "shared/assets/svgs";
import { AddEditModal } from "../../organisms/AddEditModal";
import { DeleteModal } from "../../organisms/DeleteModal";
import { useColors } from "queries/lookups";

const Probabilities = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [probability, setProbability] = useState();

  const { data: colors } = useColors();

  const { data: probabilities, isLoading: isProbabilitiesLoading } =
    useCommonLookups("probability_levels");

  const handleDeleteLookup = (probability) => {
    setIsDeleteModalOpen(true);
    setProbability(probability);
  };

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <Button
          height="24px"
          color="#2681FF"
          background="transparent"
          className="btn-with-icon addfile"
          onClick={() => setOpenAddOrEditModal("add")}
        >
          <span className="blue-circle">
            <Plus />
          </span>{" "}
          <span>Add probability</span>
        </Button>
        <ul className="ajustes">
          {probabilities?.map((probability) => (
            <li>
              <p>{probability.name}</p>
              <div className="actions">
                <Button
                  width="30px"
                  height="30px"
                  background="#0379F2"
                  className="circle-btn white-icon"
                  onClick={() => (
                    setOpenAddOrEditModal("edit"), setProbability(probability)
                  )}
                >
                  <Pencil />
                </Button>
                <Button
                  width="30px"
                  height="30px"
                  background="#FF562F"
                  className="circle-btn"
                  onClick={() => handleDeleteLookup(probability)}
                >
                  <Trash />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {openAddOrEditModal === "add" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "add"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="POST"
          keyName="probability_levels"
          name="Probability"
          colors={colors}
        />
      ) : null}
      {openAddOrEditModal === "edit" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "edit"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="PUT"
          lookup={probability}
          keyName="probability_levels"
          name="Probability"
          colors={colors}
        />
      ) : null}

      {isDeleteModalOpen ? (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsOpenDeleteModal={setIsDeleteModalOpen}
          lookup={probability}
          name="probability_levels"
        />
      ) : null}
    </div>
  );
};

export { Probabilities };
