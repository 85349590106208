import React from "react";
import styled from "styled-components";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter as S,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

const COLORS = [
  "#F17A36",
  "#6C3F98",
  "#0379F2",
  "#976AD1",
  "#FF5FBF",
  "#14B4A5",
  "#F2C53D",
  "#9F3A28",
  "#5E8B3D",
  "#2C2C54",
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <ToolTip>
        <ToolTipTitle>{payload[0].payload.name}</ToolTipTitle>
        <ToolTipText>{`Topic: ${payload[0].payload.topicName}`}</ToolTipText>
        <ToolTipText>{`Presented: ${payload[0].payload.presented}`}</ToolTipText>
        <ToolTipText>{`Status: ${payload[0].payload.statusName}`}</ToolTipText>
        <ToolTipText>{`Voted: ${payload[0].payload.voted}`}</ToolTipText>
        <ToolTipText>{`Impact: ${payload[0].payload.impactName}`}</ToolTipText>
      </ToolTip>
    );
  }

  return null;
};

const Scatter = ({ data, topics }) => (
  <div style={{ marginTop: 20, height: 225 }}>
    {Object.keys(data).length !== 0 && (
      <ResponsiveContainer width="100%" height={225}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid horizontal={false} strokeDasharray="2" />
          <XAxis
            type="category"
            allowDuplicatedCategory={false}
            dataKey="date"
            name="Month"
            tickLine={false}
            interval={"preserveStartEnd"}
            label={{ value: "Month", position: "bottom", fontSize: 14 }}
            tickMargin={10}
            fontSize={14}
            tick={{ fill: "#687B8C" }}
          />
          <YAxis
            type="number"
            dataKey="impactId"
            name="Impact"
            tickLine={false}
            interval={0}
            label={{
              value: "Impact",
              angle: -90,
              position: "left",
              fontSize: 14,
              fill: "#1C2A34",
              style: { textAnchor: "middle" },
            }}
            tickFormatter={(value) =>
              value === 1 ? "Low" : value === 2 ? "Mid" : "High"
            }
            ticks={[1, 2, 3]}
            domain={["dataMin", "dataMax"]}
            fontSize={14}
            tick={{ fill: "#687B8C" }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ strokeDasharray: "3 3" }}
          />
          <Legend
            verticalAlign="top"
            align="start"
            wrapperStyle={{
              top: 0,
              left: 90,
              fontSize: 13,
              width: "auto",
              whiteSpace: "break-spaces",
              lineHeight: "25px",
            }}
            iconSize={10}
            payload={[
              {
                value: "In progress",
                type: "circle",
              },
              {
                value: "Approved",
                type: "star",
              },
              {
                value: "Rejected",
                type: "cross",
              },
            ].concat(
              Object.keys(topics).map((t) => ({
                value: topics[t][0].topicName,
                color: COLORS[topics[t][0].topicId % COLORS.length],
                type: "plainline",
                payload: { strokeDasharray: 0 },
              }))
            )}
            fontSize={13}
          />
          <S name="InProgress" data={data[2]} shape="circle">
            {data[2]?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.topicId % COLORS.length]}
              />
            ))}
          </S>
          <S name="Approved" data={data[3]} shape="star">
            {data[3]?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.topicId % COLORS.length]}
              />
            ))}
          </S>
          <S name="Rejected" data={data[4]} shape="cross">
            {data[4]?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry.topicId % COLORS.length]}
              />
            ))}
          </S>
        </ScatterChart>
      </ResponsiveContainer>
    )}
  </div>
);

const ToolTip = styled.div`
  width: 200px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
`;

const ToolTipTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const ToolTipText = styled.p`
  font-size: 12px;
`;

export default Scatter;
