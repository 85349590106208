import React from 'react'

const StackIcon = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 9.75L12 15.75L22.5 9.75L12 3.75L1.5 9.75Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.5 13.5L12 19.5L22.5 13.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {StackIcon}