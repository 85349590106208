import { useState, useReducer, useCallback, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMutateProjectStatus } from "queries/projects";
import { CaretRightIcon as CaretRight } from "shared/assets/svgs/CaretRight";

import { Stack2Icon as Stack2 } from "shared/assets/svgs/Stack2";
import { Button } from "UI/atoms/index";
import { Spinner } from "shared/assets/svgs";

import { dragReducer } from "./reducers/dragReducer";
import { mappedProjects } from "./utils/buildStateFromStatus";
import { useColors } from "queries/lookups";
import { Modal } from "UI/atoms/index";
import { ProjectDetail } from "pages/Politicas/UI/templates/ProjectDetail";

function Seguimiento({ projects, statuses }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [project, setProject] = useState({});

  const { data: colors, isLoading } = useColors();

  const { mutate: changeProjectStatus } = useMutateProjectStatus();
  const onDragEnd = useCallback((result) => {
    if (result.reason === "DROP") {
      if (!result.destination) {
        return;
      }
      dispatch({
        type: "MOVE",
        from: result.source.droppableId,
        to: result.destination.droppableId,
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      });
      const findProjectChanged = projects?.find(
        (project) => project.id.toString() === result.draggableId
      );
      const findNewStatus = statuses.find(
        (status) => status.name === result.destination.droppableId
      );
      const projectStatus = {
        id: parseInt(findProjectChanged.id),
        status: findNewStatus.id,
      };
      changeProjectStatus(projectStatus);
    }
  }, []);

  const projectsState = mappedProjects(projects, statuses);

  const [state, dispatch] = useReducer(dragReducer, { ...projectsState });

  const stateKeys = Object.keys(state);

  const handleProject = (project) => {
    setIsOpenModal(true);
    setProject(project);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const defineCardsColors = (key) => {
    const color = colors?.find(
      (color) => color.id === projectsState[key][0]?.status?.color
    )?.code;

    return color ? color : "transparent";
  };

  return (
    <div className="seguimiento-dashboard">
      <div className="dash-area">
        <div className="dash-head-actions">
          <p className="breadcrumb">Tracker</p>
          <ul className="filters">
            <li>
              <Button
                height="44px"
                width="103px"
                color="#344854"
                background="rgba(104, 123, 140, .1)"
                className="btn-with-icon active"
              >
                <Stack2 /> <span>All</span>
              </Button>
            </li>
          </ul>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{ display: "flex" }}>
            {stateKeys.map((k, index) => (
              <Droppable droppableId={k} type="PROJECT">
                {(provided, snapshot) => {
                  return (
                    <div className="seguimientos">
                      <div className="col">
                        <h4>{k.toUpperCase()}</h4>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`drop-area`}
                          style={{
                            borderColor: defineCardsColors(k),
                          }}
                        >
                          {state[k]?.map((project, index) => {
                            return (
                              <Draggable
                                key={project.id}
                                draggableId={project.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      className="drop-area"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="card">
                                        <div className="card-info">
                                          <div className="data">
                                            <p>{project.name}</p>
                                            <small>
                                              {project.subtopics.name}
                                            </small>
                                          </div>
                                          <Button
                                            width="20px"
                                            height="20px"
                                            background="#F0F4F7"
                                            className="circle-btn"
                                            onClick={() =>
                                              handleProject(project)
                                            }
                                          >
                                            <CaretRight />
                                          </Button>
                                        </div>
                                        <ul className="card-foot">
                                          <li>{project.voted}</li>
                                          <li>{project.location}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="610px"
        padding="25px 32px"
        className="politicas-ficha"
      >
        <ProjectDetail
          setIsOpenModal={setIsOpenModal}
          projectId={project.id}
          colors={colors}
        />
      </Modal>
    </div>
  );
}

export { Seguimiento };
