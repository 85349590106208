import React from "react";
import { Input, Select, Error } from "UI/atoms";
import { CalendarIcon as Calendar } from "shared/assets/svgs/Calendar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ButtonActions } from "../../organisms/ButtonActions";
import { RegularText } from "UI/atoms";

const Parameters = ({
  formik,
  lookups,
  setSelectedTab,
  isEdit,
  responseError,
  successResponse,
  isLastTab,
}) => {
  const successText = isEdit ? "changed" : "created";
  return (
    <div className="tablayer tab-parametros">
      <div className="field-row">
        <ul className="field-colums">
          <li>
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <Select
              name="status"
              id="status"
              width="173px"
              defaultValue={formik.values.status}
              onChange={(topic) => {
                formik.setFieldValue("status", parseInt(topic.target.value));
              }}
            >
              <option value={0}>Select status</option>
              {lookups.statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Select>
            {formik.errors.status && formik.touched.status ? (
              <Error>{formik.errors.status}</Error>
            ) : null}
          </li>
          <li>
            <label htmlFor="probability" className="form-label">
              Probability
            </label>
            <Select
              name="probability"
              id="probability"
              width="173px"
              defaultValue={formik.values.probability}
              onChange={(probability) => {
                formik.setFieldValue(
                  "probability",
                  parseInt(probability.target.value)
                );
              }}
            >
              <option value={0}>Select probability</option>
              {lookups.probabilities.map((probability) => (
                <option key={probability.id} value={probability.id}>
                  {probability.name}
                </option>
              ))}
            </Select>
            {formik.errors.probability && formik.touched.probability ? (
              <Error>{formik.errors.probability}</Error>
            ) : null}
          </li>
          <li>
            <label htmlFor="impact" className="form-label">
              Impact
            </label>
            <Select
              name="impact"
              id="impact"
              width="173px"
              defaultValue={formik.values.impact}
              onChange={(impact) => {
                formik.setFieldValue("impact", parseInt(impact.target.value));
              }}
            >
              <option value={0}>Select impact</option>
              {lookups.impacts.map((impact) => (
                <option key={impact.id} value={impact.id}>
                  {impact.name}
                </option>
              ))}
            </Select>
            {formik.errors.impact && formik.touched.impact ? (
              <Error>{formik.errors.impact}</Error>
            ) : null}
          </li>
          <li>
            <label htmlFor="success" className="form-label">
              Strategy worked?
            </label>
            <Select
              name="success"
              id="success"
              width="173px"
              defaultValue={formik.values.success}
              onChange={(success) => {
                formik.setFieldValue("success", parseInt(success.target.value));
              }}
            >
              <option value={0}>Select strategy</option>
              {lookups.successTypes.map((success) => (
                <option key={success.id} value={success.id}>
                  {success.name}
                </option>
              ))}
            </Select>
            {formik.errors.success && formik.touched.success ? (
              <Error>{formik.errors.success}</Error>
            ) : null}
          </li>
        </ul>
      </div>
      <div className="field-row">
        <ul className="field-colums">
          <li>
            <label htmlFor="presented" className="form-label">
              Presented date:
            </label>
            <div className="field-with-icon">
              <DatePicker
                name="presented"
                id="presented"
                selected={formik.values.presented}
                onChange={(val) => {
                  formik.setFieldValue("presented", val);
                }}
                className="react-datepicker__input-field"
                isClearable
              />
            </div>
            {formik.errors.presented && formik.touched.presented ? (
              <Error>{formik.errors.presented}</Error>
            ) : null}
          </li>
          <li>
            <label htmlFor="voted" className="form-label">
              Voted date:
            </label>
            <div className="field-with-icon">
              <DatePicker
                name="voted"
                id="voted"
                selected={formik.values.voted}
                onChange={(val) => {
                  formik.setFieldValue("voted", val);
                }}
                className="react-datepicker__input-field"
                isClearable
              />
            </div>
            {formik.errors.voted && formik.touched.voted ? (
              <Error>{formik.errors.voted}</Error>
            ) : null}
          </li>
        </ul>
      </div>
      <div className="field-row">
        <label htmlFor="strategy_links" className="form-label">
          Strategy link
        </label>
        <Input
          type="text"
          name="strategy_links[0]"
          id="strategy_links[0]"
          height="46px"
          className="form-control"
          onChange={(val) => {
            formik.setFieldValue("strategy_links[0]", {
              url: val.target.value,
            });
          }}
          value={formik.values.strategy_links[0]?.url || ""}
        />
        {formik.errors.strategy_links && formik.touched.strategy_links ? (
          <Error>{formik.errors.strategy_links[0]?.url}</Error>
        ) : null}
      </div>
      <div className="field-row">
        <label htmlFor="strategy_links" className="form-label">
          Link Nº 2
        </label>
        <Input
          type="text"
          name="strategy_links[1]"
          id="strategy_links[1]"
          height="46px"
          className="form-control"
          onChange={(val) => {
            formik.setFieldValue("strategy_links[1]", {
              url: val.target.value,
            });
          }}
          value={formik.values.strategy_links[1]?.url || ""}
        />
        {formik.errors.strategy_links && formik.touched.strategy_links ? (
          <Error>{formik.errors.strategy_links[1]?.url}</Error>
        ) : null}
      </div>

      {successResponse && (
        <div style={{ display: "block" }}>
          <RegularText size="16px" color="#2EC07F">
            Your project has been successfully {successText}!{" "}
            <Link to="/policies" style={{ fontSize: "16px", color: "#0379F2" }}>
              Click here
            </Link>{" "}
            to see list of projects.
          </RegularText>
        </div>
      )}

      <ButtonActions
        isEdit={isEdit}
        isLastTab={isLastTab}
        responseError={responseError}
        submitCount={formik.submitCount}
        onSetSelectedTab={setSelectedTab}
        nextTab="attachments"
        prevTap="information"
      />
    </div>
  );
};

export { Parameters };
