import React from 'react'

const DesktopIcon = ({ color }) => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 4.29541H4.5V10.0227H10.5V4.29541Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.5 4.29541H13.5V10.0227H19.5V4.29541Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5 12.8864H4.5V18.6136H10.5V12.8864Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.5 12.8864H13.5V18.6136H19.5V12.8864Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export {DesktopIcon}