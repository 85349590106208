import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "../utils/api-client";

const useCreateUser = (onError) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`users/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
      onSettled: () => queryClient.invalidateQueries("users"),
      onError: (err) => onError(err)
    }
  );
};

const useEditUser = (userId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`users/${userId}/`, {
        method: "PUT",
        data,
      },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries("users")
    }
  );
};

const useEditPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client('users/change_password/', {
        method: "PUT",
        data,
      },
    ),
    {
      onSuccess: () => queryClient.invalidateQueries("users")
    }
  );
};

const useChangePasswordInFirstLogin = () => {
  return useMutation(
    (data) =>
      client(`users/change_password/`, {
        method: "PUT",
        data,
      },
      )
  );
};

export {useCreateUser, useEditUser, useEditPassword, useChangePasswordInFirstLogin}