import { Button } from "UI/atoms";
import { Check } from "shared/assets/svgs";
import { CaretRightIcon } from "shared/assets/svgs/CaretRight";
import { useNavigate } from "react-router-dom";

const ButtonActions = ({ isLastTab, onSetSelectedTab, nextTab, prevTap }) => {
  const navigate = useNavigate();
  return (
    <>
      {isLastTab ? (
        <div className="actions-row">
          <Button
            width="124px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => navigate("/policies")}
          >
            Cancel
          </Button>
          <Button
            width="124px"
            className="btn-with-icon white-icon"
            type="submit"
          >
            <Check /> <span>Save</span>
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            width="100px"
            background="#F0F4F7"
            color="#344854"
            borderRadius="10px"
            className="btn-with-icon white-icon"
            onClick={() => onSetSelectedTab(prevTap)}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
              }}
            >
              {"Back"}
            </span>
          </Button>

          <Button
            width="100px"
            background="#0379F2"
            color="white"
            borderRadius="10px"
            className="btn-with-icon white-icon"
            onClick={() => onSetSelectedTab(nextTab)}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0 10px",
              }}
            >
              {"Next"}
            </span>
            <CaretRightIcon />
          </Button>
        </div>
      )}
    </>
  );
};

export { ButtonActions };
