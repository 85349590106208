import React, { useState } from "react";
import { useCommonLookups } from "queries/lookups";
import { Button } from "UI/atoms";
import { Plus, Pencil, Trash } from "shared/assets/svgs";
import { AddEditModal } from "../../organisms/AddEditModal";

const HasWorked = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [success, setSuccess] = useState();

  const { data: successTypes, isLoading: isSuccessLoading } =
    useCommonLookups("success_levels");

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <Button
          height="24px"
          color="#2681FF"
          background="transparent"
          className="btn-with-icon addfile"
          onClick={() => setOpenAddOrEditModal("add")}
        >
          <span className="blue-circle">
            <Plus />
          </span>{" "}
          <span>Add strategy</span>
        </Button>
        <ul className="ajustes">
          {successTypes?.map((success) => (
            <li>
              <p>{success.name}</p>
              <div className="actions">
                <Button
                  width="30px"
                  height="30px"
                  background="#0379F2"
                  className="circle-btn white-icon"
                  onClick={() => (
                    setOpenAddOrEditModal("edit"), setSuccess(success)
                  )}
                >
                  <Pencil />
                </Button>
                <Button
                  width="30px"
                  height="30px"
                  background="#FF562F"
                  className="circle-btn"
                >
                  <Trash />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {openAddOrEditModal === "add" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "add"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="POST"
          keyName="success_levels"
          name="Success level"
        />
      ) : null}
      {openAddOrEditModal === "edit" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "edit"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="PUT"
          lookup={success}
          keyName="success_levels"
          name="Success level"
        />
      ) : null}
    </div>
  );
};

export { HasWorked };
