import { ErrorFallback } from "UI/organisms/ErrorFallback";
import { AuthenticatedApp } from "authenticated-app";
import { Menubar } from "pages/index";
import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import GlobalStyle from "utils/globalStyles";
import { theme } from "utils/theme";
import { useAuth } from "auth/context";
import { useLocation } from "react-router-dom";
import { Suspense } from "react";

import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { Spinner } from "shared/assets/svgs/index";

function App() {
  const location = useLocation();

  const { user } = useAuth();

  return (
    <div className="dashboard">
      <ModalProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <nav>
            <Menubar user={user} />
          </nav>
          <main>
            <Suspense fallback={<Spinner />}>
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    onReset={reset}
                    FallbackComponent={ErrorFallback}
                    resetErrorBoundary
                    // Esta key permite triggerear un re-render que desmonta el componente ErrorFallback en
                    // caso de cambiar de ruta
                    key={location.pathname}
                  >
                    <AuthenticatedApp user={user} />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </Suspense>
          </main>
        </ThemeProvider>
      </ModalProvider>
    </div>
  );
}

export default App;
