import React from "react";
import styled from "styled-components";
import {
  ResponsiveContainer,
  BarChart,
  Bar as B,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <ToolTip>
        <ToolTipTitle>{payload[0].payload.name}</ToolTipTitle>
        <ToolTipText>{`Topic: ${payload[0].payload.topic}`}</ToolTipText>
        <ToolTipText>{`Presented: ${payload[0].payload.presented}`}</ToolTipText>
        <ToolTipText>{`Status: ${payload[0].payload.status}`}</ToolTipText>
        <ToolTipText>{`Voted: ${payload[0].payload.voted}`}</ToolTipText>
        {/* <ToolTipText>{`Impact: ${payload[0].payload.impact}`}</ToolTipText> */}
      </ToolTip>
    );
  }

  return null;
};

const labelFormatter = (value) => {
  return value + "w";
};

const Bar = ({ data }) => {
  return (
    <div style={{ marginTop: 20, height: 225 }}>
      <ResponsiveContainer width="100%" height={225}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          }}
        >
          <XAxis type="number" hide />
          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={false}
            fontSize={14}
            width={200}
          />
          <Tooltip content={<CustomTooltip />} />
          <B
            dataKey="average_weeks"
            barSize={40}
            fill="#413ea0"
            label={{ fontSize: 14, fill: "white", formatter: labelFormatter }}
            name="Weeks"
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const ToolTip = styled.div`
  width: 200px;
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
`;

const ToolTipTitle = styled.p`
  font-size: 15px;
  font-weight: 600;
`;

const ToolTipText = styled.p`
  font-size: 12px;
`;

export default Bar;
