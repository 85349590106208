import React, { useState } from "react";
import { useCommonLookups } from "queries/lookups";
import { Button } from "UI/atoms";
import { Plus, Pencil, Trash } from "shared/assets/svgs";
import { AddEditTopicModal } from "./AddEditTopicModal";
import { DeleteModal } from "../../organisms/DeleteModal";

const Topics = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [topic, setTopic] = useState();

  const { data: subtopics, isLoading: isTopicsLoading } =
    useCommonLookups("subtopics");

  const { data: topicss, isLoading: isTopicssLoading } =
    useCommonLookups("topics");

  const handleDeleteLookup = (topic) => {
    setIsDeleteModalOpen(true);
    setTopic(topic);
  };

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <Button
          height="24px"
          color="#2681FF"
          background="transparent"
          className="btn-with-icon addfile"
          onClick={() => setOpenAddOrEditModal("add")}
        >
          <span className="blue-circle">
            <Plus />
          </span>{" "}
          <span>Add topic</span>
        </Button>
        <ul className="ajustes">
          {topicss?.map((topic) => (
            <li>
              <p>{topic.name}</p>
              <div className="actions">
                <Button
                  width="30px"
                  height="30px"
                  background="#0379F2"
                  className="circle-btn white-icon"
                  onClick={() => (
                    setOpenAddOrEditModal("edit"), setTopic(topic)
                  )}
                >
                  <Pencil />
                </Button>
                <Button
                  width="30px"
                  height="30px"
                  background="#FF562F"
                  className="circle-btn"
                  onClick={() => handleDeleteLookup(topic)}
                >
                  <Trash />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {openAddOrEditModal === "add" ? (
        <AddEditTopicModal
          isOpenModal={openAddOrEditModal === "add"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="POST"
          keyName="topic"
          name="Topic"
        />
      ) : null}
      {openAddOrEditModal === "edit" ? (
        <AddEditTopicModal
          isOpenModal={openAddOrEditModal === "edit"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="PUT"
          topic={topic}
          subtopics={subtopics}
          keyName="topic"
          name="Country"
        />
      ) : null}

      {isDeleteModalOpen ? (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsOpenDeleteModal={setIsDeleteModalOpen}
          lookup={topic}
          name="topics"
        />
      ) : null}
    </div>
  );
};

export { Topics };
