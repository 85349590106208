const getProjectsAmountPerTopic = (projects = []) => {
  const topics = projects.map(project => project.subtopics.map(subtopic => {
    return {
      name:  subtopic.topic.name
    }
  }))

  const mergedSubTopics = [].concat.apply([], topics);
  
  const results = Object.values(
    mergedSubTopics.reduce((acc, topic) => {
      acc[topic.name] = acc[topic.name]
        ? {...topic, count: 1 + acc[topic.name].count}
        : {...topic, count: 1};
      return acc;
    }, {})
  )

  const total = results.reduce((acc, el) => acc + el.count, 0)

  return {
    results,
    total
  }
}
  
  export {getProjectsAmountPerTopic}