import React from 'react'
import styled from 'styled-components'

const Error = ({children, color, style}) => {
  return (
    <StyledError 
      style={style}
      color={color}
    >
      {children}
    </StyledError>
  )
}

const StyledError = styled.span`
  margin: 0.5rem 0;
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.error}
`

export {Error}