import React, { useState } from "react";
import { useCommonLookups } from "queries/lookups";
import { Search, ListItem, Button, RegularText } from "UI/atoms";
import { CaretRightIcon as CaretRight } from "shared/assets/svgs/CaretRight";
import { Trash, Check, Spinner } from "shared/assets/svgs";
import { Link, useNavigate } from "react-router-dom";
import { search } from "./utils/search";
import { Error } from "UI/atoms";
import { ButtonActions } from "../../organisms/ButtonActions";

const errors = {
  name: "Name of regulation",
  legal_id: "ID",
  location: "Country",
  entity: "Entity",
  description: "Description",
  status: "Status",
  probability: "Probability",
  impact: "Impact",
  presented: "Presented date",
  consultant: "Consultant",
  subtopics: "Topic and subtopic",
};

const Asignation = ({
  formik,
  responseError,
  successResponse,
  setSelectedTab,
  isEdit,
  isLastTab,
}) => {
  const errorsKeys = Object.keys(formik.errors);
  const hasErrors = errorsKeys.length > 0;

  const mappedErrors = errorsKeys.map((err) => errors[err]);

  const errorsText = hasErrors
    ? `The following fields are required: ${mappedErrors.join(
        ", "
      )}. Please complete and try again.`
    : null;

  const successText = isEdit ? "changed" : "created";
  const { consultant } = formik.values;

  const { data: availableConsultants, isLoading: isConsultantsLoading } =
    useCommonLookups("users");

  const activeConsultants = availableConsultants.filter(consultant => consultant.is_active === true);
  
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");

  const filteredConsultants = search(activeConsultants, query);

  const handleConsultantsAsignation = (consultantAssigned) => {
    if (consultant?.some((ac) => ac.id === consultantAssigned.id)) {
      setError("consultant-already-exists");
      return;
    }

    if (consultant?.length > 0) {
      const currentValues = formik.getFieldProps("consultant");
      const newValue = [...currentValues?.value, consultantAssigned];
      formik.setFieldValue("consultant", newValue);
    } else {
      formik.setFieldValue("consultant", [consultantAssigned]);
    }

    setError("");
  };

  const deleteAsignedConsultant = (consultantId) => {
    const filterConsultants = consultant.filter(
      (asignedConsultant) => asignedConsultant.id !== consultantId
    );
    formik.setFieldValue("consultant", filterConsultants);
  };

  return (
    <div className="tablayer tab-asignacion">
      <div className="cols">
        <div className="col">
          <div className="search-area">
            <Search
              placeholder="Buscar nombre consultor..."
              isSearch={true}
              id="search"
              name="search"
              className="form-search"
              width="100%"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <ul className="asignaciones">
            {filteredConsultants?.map((consultant) => (
              <li key={consultant.id}>
                <ListItem className="item">
                  <p>
                    {consultant.first_name} {consultant.last_name}
                  </p>
                  <h4>{consultant.country.name}</h4>
                  <Button
                    type="button"
                    height="26px"
                    width="26px"
                    className="circle-btn"
                    onClick={() => handleConsultantsAsignation(consultant)}
                  >
                    <CaretRight />
                  </Button>
                </ListItem>
              </li>
            ))}
            {error === "consultant-already-exists" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Consultant has already been added
              </span>
            ) : null}
          </ul>
        </div>
        <div className="col asignados-col">
          <h3>Assigned to:</h3>
          <ul className="asignados">
            {consultant?.map((asignedConsultant) => (
              <li key={asignedConsultant.id}>
                <ListItem className="item">
                  <p>
                    {asignedConsultant.first_name} {asignedConsultant.last_name}
                  </p>
                  <h4>{asignedConsultant.country.name}</h4>
                  <Button
                    type="button"
                    height="30px"
                    width="30px"
                    background="#FF562F"
                    className="circle-btn"
                    onClick={() =>
                      deleteAsignedConsultant(asignedConsultant.id)
                    }
                  >
                    <Trash />
                  </Button>
                </ListItem>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div style={{ display: "block" }}>
        <Error>{errorsText}</Error>
      </div>

      {successResponse && (
        <div style={{ display: "block" }}>
          <RegularText size="16px" color="#2EC07F">
            Your project has been successfully {successText}!{" "}
            <Link to="/policies" style={{ fontSize: "16px", color: "#0379F2" }}>
              Click here
            </Link>{" "}
            to see list of projects.
          </RegularText>
        </div>
      )}

      <ButtonActions
        isEdit={isEdit}
        isLastTab={isLastTab}
        responseError={responseError}
        submitCount={formik.submitCount}
        onSetSelectedTab={setSelectedTab}
      />
    </div>
  );
};

export { Asignation };
