import React, { useState } from "react";
import { Badge, Button, Error, Input, RegularText, Select } from "UI/atoms";
import { Check } from "shared/assets/svgs";

import { useMutateLookup, useAddLookup } from "queries/lookups";

const AddEdit = ({
  setOpenAddOrEditModal,
  actionText,
  method,
  lookup,
  keyName,
  name,
  colors,
}) => {
  const [value, setValue] = useState();
  const [color, setColor] = useState({});
  const [error, setError] = useState("");
  const { mutate: edit } = useMutateLookup(keyName);
  const { mutate: add } = useAddLookup(keyName);

  const findColor = () => {
    if (method === "PUT") {
      return colors?.find((color) => color.id === lookup?.color).code;
    } else {
      return;
    }
  };
  // const findColor = colors?.find(color => color.id === lookup?.color)

  const handleSideEffectAction = () => {
    if (method !== "PUT") {
      if (!value || !color) {
        setError("empty-value");
        return;
      }
    }

    if (method === "PUT") {
      edit({ name: value, color: color.id, id: lookup.id });
    }
    if (method === "POST") {
      add({ name: value, color: color.id });
    }
    setOpenAddOrEditModal("");
  };

  const handleInputOnChange = (value) => {
    setValue(value);
  };

  return (
    <>
      <div className="field-row">
        <h4>{actionText}</h4>
        <div className="field-with-icon" style={{ margin: "1rem 0" }}>
          <Input
            type="text"
            name={name}
            height="46px"
            className="form-control"
            placeholder={name}
            defaultValue={lookup?.name ? lookup.name : ""}
            onChange={(e) => handleInputOnChange(e.target.value)}
          />
        </div>
        {colors ? (
          <>
            <RegularText>Select a color:</RegularText>
            <li
              style={{
                display: "flex",
                margin: "1rem 0 0 0",
                flexWrap: "wrap",
              }}
            >
              {colors?.map((color) => (
                <Badge
                  key={color.name}
                  value={color.code}
                  background={color.code}
                  height="fit-content"
                  margin="5px"
                  onClick={() => setColor(color)}
                >
                  {color.name}
                </Badge>
              ))}
              <div style={{ margin: "1rem 0 0 0" }}>
                <RegularText margin="1rem 0 0 0">
                  {color && "Selected color:"}
                </RegularText>
                <Badge
                  margin="1rem 0"
                  background={color.id ? color.code : findColor()}
                >
                  {lookup?.name ? lookup?.name : "Status"}
                </Badge>
              </div>
            </li>
          </>
        ) : null}
      </div>
      {error ? <Error>There are empty fields.</Error> : null}
      <div className="actions-row">
        <Button
          width="133px"
          background="#F0F4F7"
          color="#344854"
          className="btn-with-icon"
          onClick={() => setOpenAddOrEditModal("")}
        >
          Cancel
        </Button>
        <Button
          width="133px"
          className="btn-with-icon white-icon"
          onClick={() => handleSideEffectAction()}
        >
          <Check /> <span>Confirm</span>
        </Button>
      </div>
    </>
  );
};

export { AddEdit };
