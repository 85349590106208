import styled from "styled-components";

export const Card = ({ children }) => {
  return (
    <div>
      <CardContainer>{children}</CardContainer>
    </div>
  );
};

const CardContainer = styled.div`
  background: #fefefe;
  border-radius: 40px;
  padding: 44px 70px;
  max-width: 429px;
  margin-bottom: 38px;
  @media screen and (max-width: 1350px) {
    padding: 30px;
  }
`;
