import { Power } from "shared/assets/svgs/SidebarIcons";

import { ListItem } from "UI/atoms/index";
import { Link, useLocation } from "react-router-dom";
import { handleSidebarAccordingToUserRole } from "./utils/handleSidebarAccordingToUserRole";
import { useAuth } from "auth/context";

function Menubar({ user }) {
  const { pathname } = useLocation();
  const { logout } = useAuth();
  const listItems = handleSidebarAccordingToUserRole(user?.role);

  return (
    <>
      <div className="head">
        <div className="block">
          <p>
            SSSA
            <br /> <span>Legislative HUB</span>
          </p>
        </div>
      </div>
      <ul>
        {listItems?.map((li) => (
          <li key={li.route} style={{ textDecoration: "none" }}>
            <Link to={li.route}>
              <ListItem
                className={`/${li.route}` === pathname ? "selected" : null}
              >
                <span>{li.icon}</span> {li.text}
              </ListItem>
            </Link>
          </li>
        ))}
        <li style={{ textDecoration: "none" }} onClick={() => logout()}>
          <ListItem>
            <span>
              <Power />
            </span>{" "}
            Log out
          </ListItem>
        </li>
      </ul>
    </>
  );
}

export { Menubar };
