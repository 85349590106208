const getSpanishDateFormat = (isoDateString) => {
  const date = new Date(isoDateString); // Crea un objeto Date a partir de la cadena de fecha ISO 8601
  const formattedDate = date.toLocaleString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }); // Crea la cadena de fecha formateada en el formato dd/mm/yyyy

  return formattedDate;
};

export { getSpanishDateFormat };
