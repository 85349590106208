import React from "react";
import styled from 'styled-components'

export default function Card({capText, embedComp}) {



  return (
      <div>
          <h2>{capText}</h2>
          <div>{embedComp}</div>
      </div>

  );


}





