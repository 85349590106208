import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const useCommonLookups = (lookup) => {
  const { data, isLoading } = useQuery({
    queryKey: lookup,
    queryFn: () =>
      client(
        `${lookup}`
        // {token: user.token}
      ).then((data) => data),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {
    data: data ?? [],
    isLoading,
  };
};

const useAddLookup = (lookup, setOpenAddOrEditModal) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`${lookup}/`, {
        method: "POST",
        data: {
          name: data.name,
          color: data.color,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("lookups"),
      onSettled: () => queryClient.invalidateQueries("lookups"),
    }
  );
};

const useDeleteLookup = (lookup, name) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      client(`${name}/${lookup.id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(name),
      onSettled: () => queryClient.invalidateQueries(name),
    }
  );
};

const useMutateLookup = (lookup) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`${lookup}/${data.id}/`, {
        method: "PUT",
        data: {
          name: data.name,
          color: data.color,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("lookups"),
    }
  );
};

const useGetSubtopicByTopicId = (topicId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["subtopics", topicId],
    queryFn: () => client(`topics/${topicId}`).then((data) => data.subtopics),
    enabled: !!topicId,
  });
  return {
    data: data ?? [],
    isLoading,
  };
};

const useEditTopic = (topicId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(
        `topics/${topicId}/`,
        {
          method: "PUT",
          data: { name: data.name },
        },
        { enabled: !!topicId }
      ),
    {
      onSuccess: () => queryClient.invalidateQueries("topics"),
    }
  );
};

const useEditSubtopic = (topicId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(
        `subtopics/${data.subtopicId}/`,
        {
          method: "PUT",
          data: {
            name: data.name,
            topic: topicId,
          },
        },
        { enabled: !!data.id }
      ),
    {
      onSuccess: () => queryClient.invalidateQueries("subtopics"),
    }
  );
};

const useAddSubtopic = (topicId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(
        "subtopics/",
        {
          method: "POST",
          data: {
            name: data.name,
            topic: topicId,
          },
        },
        { enabled: !!data.name }
      ),
    {
      onSuccess: () => queryClient.invalidateQueries("subtopics"),
    }
  );
};

const useAddTopicWithSubtopics = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(
        "topics/",
        {
          method: "POST",
          data,
        },
        { enabled: Object.keys(data).length > 0 }
      ),
    {
      onSuccess: () => queryClient.invalidateQueries("topics"),
    }
  );
};

const useColors = () => {
  const { data, isLoading } = useQuery({
    queryKey: "colors",
    queryFn: () => client("colors").then((data) => data),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {
    data: data ?? [],
    isLoading,
  };
};

export {
  useCommonLookups,
  useMutateLookup,
  useAddLookup,
  useEditSubtopic,
  useEditTopic,
  useAddSubtopic,
  useAddTopicWithSubtopics,
  useGetSubtopicByTopicId,
  useDeleteLookup,
  useColors,
};
