import React, { useState } from "react";
import { useColors, useCommonLookups } from "queries/lookups";
import { Button } from "UI/atoms";
import { Plus, Pencil, Trash } from "shared/assets/svgs";
import { AddEditModal } from "../../organisms/AddEditModal";
import { DeleteModal } from "../../organisms/DeleteModal";

const Statuses = () => {
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [status, setStatus] = useState();

  const { data: colors } = useColors();

  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project_status");

  const handleDeleteLookup = (status) => {
    setIsDeleteModalOpen(true);
    setStatus(status);
  };

  return (
    <div className="tablayer tab-pais">
      <div className="ajustes-layer">
        <Button
          height="24px"
          color="#2681FF"
          background="transparent"
          className="btn-with-icon addfile"
          onClick={() => setOpenAddOrEditModal("add")}
        >
          <span className="blue-circle">
            <Plus />
          </span>{" "}
          <span>Add status</span>
        </Button>
        <ul className="ajustes">
          {statuses?.map((status) => (
            <li>
              <p>{status.name}</p>
              <div className="actions">
                <Button
                  width="30px"
                  height="30px"
                  background="#0379F2"
                  className="circle-btn white-icon"
                  onClick={() => (
                    setOpenAddOrEditModal("edit"), setStatus(status)
                  )}
                >
                  <Pencil />
                </Button>
                <Button
                  width="30px"
                  height="30px"
                  background="#FF562F"
                  className="circle-btn"
                  onClick={() => handleDeleteLookup(status)}
                >
                  <Trash />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {openAddOrEditModal === "add" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "add"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="POST"
          keyName="project_status"
          name="Success level"
          colors={colors}
        />
      ) : null}
      {openAddOrEditModal === "edit" ? (
        <AddEditModal
          isOpenModal={openAddOrEditModal === "edit"}
          setOpenAddOrEditModal={setOpenAddOrEditModal}
          method="PUT"
          lookup={status}
          keyName="project_status"
          name="Status"
          colors={colors}
        />
      ) : null}

      {isDeleteModalOpen ? (
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsOpenDeleteModal={setIsDeleteModalOpen}
          lookup={status}
          name="project_status"
        />
      ) : null}
    </div>
  );
};

export { Statuses };
