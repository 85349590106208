import styled from "styled-components";

const Select = ({
  name,
  id,
  defaultValue,
  onChange,
  children,
  labelText,
  bg = "#FFFFFF",
  padding,
  border,
  width,
  margin,
  className,
  labelClassName,
  labelHtmlFor,
}) => {
  return (
    <div className={className}>
      <label className={labelClassName} htmlFor={labelHtmlFor}>
        {labelText}:
      </label>
      <StyledSelect
        name={name}
        id={id}
        padding={padding}
        defaultValue={defaultValue}
        onChange={onChange}
        bg={bg}
        border={border}
        width={width}
        margin={margin}
        className="select"
      >
        {children}
      </StyledSelect>
    </div>
  );
};

const StyledSelect = styled.select`
  display: block;

  width: ${(props) => props.width || "100%"};
  height: 46px;

  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.dark};

  border: ${(props) => props.border || "1px solid rgba(104, 123, 140, 0.5)"};
  border-radius: 10px;

  padding: ${(props) => props.padding || "0 18px"};

  background-color: ${(props) => props.bg};

  margin: ${(props) => props.margin || "0"};
`;

export { Select };
