const mappedProjects = (projects = [], statuses) => {
  const statusesKeysObj = statuses?.reduce((acc, el) => {
    return {
      ...acc,
      [el.name]: []
    }
  }, {})

  const newProjects = projects?.map(project => {
    return {
      id: project.id.toString(),
      name: project.name,
      status: project.status,
      subtopics: project.subtopics[0].topic,
      voted: project.voted,
      location: project.location.name,
      description: project.description
    }
  })

  const buildProjectsStateAccordingToStatus = newProjects?.reduce((acc, project) => {
    const keys = Object.keys(acc)
    if(keys.includes(project.status.name)) {
      return {
        ...acc,
        [project.status.name]: [...acc[project.status.name], project]
      }
    } else {
      return {
        ...acc,
        ...statusesKeysObj,
        [project.status.name]: [project]
      }
    }
  }, {})

  return buildProjectsStateAccordingToStatus
} 



export {mappedProjects}