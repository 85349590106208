import { AddEditProject, Politicas } from "pages/index";
import { Navigate, Route, Routes } from "react-router-dom";

const ConsultantRoutes = ({ projects }) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/policies" />} />
      <Route
        exact
        path="/policies"
        element={<Politicas projects={projects} />}
      ></Route>
      <Route exact path="/new-project" element={<AddEditProject />}></Route>
      <Route
        exact
        path="/edit-project/:id"
        element={<AddEditProject />}
      ></Route>
    </Routes>
  );
};

const ConsultantApp = ({ projects }) => {
  return (
    <>
      <ConsultantRoutes projects={projects} />
    </>
  );
};

export { ConsultantApp };
