import React from "react";

import { AdminApp } from "admin-app";
import { CoordinatorApp } from "coordinator-app";
import { ConsultantApp } from "consultant-app";

const AppFlowByRole = ({ projects, role }) => {
  return (
    <>
      {role === "Administrators" && <AdminApp projects={projects} />}
      {role === "Coordinators" && <CoordinatorApp projects={projects} />}
      {role === "Consultants" && <ConsultantApp projects={projects} />}
    </>
  );
};

export { AppFlowByRole };
