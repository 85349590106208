import React from 'react'

const LinkIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18.1333" height="18.1333" transform="translate(0.93335 0.93335)" fill="#EBF2FF"/>
        <path d="M7.59558 12.4035L12.4039 7.59521" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.2016 13.6062L9.19816 15.6096C8.5605 16.2471 7.69575 16.6051 6.79412 16.6051C5.89248 16.605 5.0278 16.2468 4.39025 15.6092C3.75269 14.9717 3.39449 14.107 3.39441 13.2054C3.39433 12.3037 3.75239 11.439 4.38983 10.8013L6.3933 8.79785" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.6062 11.2016L15.6096 9.19816C16.2471 8.5605 16.6052 7.69575 16.6051 6.79412C16.605 5.89248 16.2468 5.0278 15.6092 4.39025C14.9717 3.75269 14.107 3.39449 13.2054 3.39441C12.3037 3.39433 11.439 3.75239 10.8013 4.38983L8.79785 6.3933" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {LinkIcon}