import React from "react";
import styled from "styled-components";

const SubtemaBar = ({ subtipo, cantidad }) => {
  return (
    <>
      <StyledText title={subtipo}>{subtipo}</StyledText>
      <strong>{cantidad}</strong>
    </>
  );
};

const StyledText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export { SubtemaBar };
