import styled from "styled-components";

const Input = ({
  border,
  borderRadius,
  name,
  value,
  onChange,
  defaultValue,
  type,
  placeholder,
  bg = "#FFFFFF",
  hasBorder = false,
  padding,
  margin,
  width,
  className,
  height,
  style,
  multiple,
  onKeyPress,
  autoComplete
}) => {
  return (
    <>
      <StyledInput
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        value={value}
        type={type}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
        margin={margin}
        width={width}
        height={height}
        className={className}
        border={border}
        borderRadius={borderRadius}
        style={style}
        multiple={multiple}
        onKeyPress={onKeyPress}
        autoComplete={autoComplete}
      />
    </>
  );
};

const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;

  background: ${(props) => props.bg};

  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height};

  border: ${(props) =>
    props.border ? props.border : "1px solid rgba(104, 123, 140, 0.5)"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "10px"};

  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
`;
export { Input };
