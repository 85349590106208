import React from "react";
import { Input, Select, Button, Error } from "UI/atoms";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { useNavigate } from "react-router-dom";
import { RegularText } from "UI/atoms";
import { Link } from "react-router-dom";
import { ButtonActions } from "../../organisms/ButtonActions";

const texts = {
  "Name of regulation":
    "Title | Type of Legal Norm (BoL, Decree, Resolution, etc.)",
  ID: "Number / Local identification code. Please, identify the norm with its official number or code.",
  Entity: "Place of Origin (House of Representatives, Senate, etc.)",
  Description: "Describe the projects, its impact and the geographical scope",
};

const Information = ({
  formik,
  lookups,
  setSelectedTab,
  isEdit,
  responseError,
  successResponse,
  isLastTab,
}) => {
  const successText = isEdit ? "changed" : "created";
  return (
    <div className="tablayer tab-informacion">
      <div className="field-row">
        <Tooltip
          placement="right"
          showArrow={false}
          overlay={texts["Name of regulation"]}
          overlayStyle={{ background: "transparent" }}
          overlayInnerStyle={{
            borderRadius: "10px",
            font: '1em "DM Sans", Arial, Helvetica, sans-serif',
          }}
        >
          <label htmlFor="name" className="form-label">
            Name of regulation
          </label>
        </Tooltip>

        <Input
          id="name"
          name="name"
          type="text"
          height="46px"
          className="form-control"
          onChange={(name) => {
            formik.setFieldValue("name", name.target.value);
          }}
          value={formik.values.name}
        />
        {formik.errors.name && formik.touched.name ? (
          <Error>{formik.errors.name}</Error>
        ) : null}
      </div>
      <div className="field-row">
        <ul className="field-colums">
          <li>
            <Tooltip
              showArrow={false}
              placement="right"
              overlay={texts["ID"]}
              overlayStyle={{ background: "white" }}
              overlayInnerStyle={{
                borderRadius: "10px",
                font: '1em "DM Sans", Arial, Helvetica, sans-serif',
              }}
            >
              <label htmlFor="legal_id" className="form-label">
                ID
              </label>
            </Tooltip>
            <Input
              type="text"
              name="legal_id"
              id="legal_id"
              height="46px"
              width="105px"
              className="form-control"
              onChange={(legalId) => {
                formik.setFieldValue("legal_id", legalId.target.value);
              }}
              value={formik.values.legal_id}
            />
            {formik.errors.legal_id && formik.touched.legal_id ? (
              <Error>{formik.errors.legal_id}</Error>
            ) : null}
          </li>

          <li>
            <label htmlFor="location" className="form-label">
              Country
            </label>

            <Select
              name="location"
              id="location"
              width="173px"
              defaultValue={formik.values.location}
              onChange={(location) => {
                formik.setFieldValue(
                  "location",
                  parseInt(location.target.value)
                );
              }}
            >
              <option value={0}>Select country</option>
              {lookups.countries.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </Select>
            {formik.errors.location && formik.touched.location ? (
              <Error>{formik.errors.location}</Error>
            ) : null}
          </li>
          <li>
            <Tooltip
              showArrow={false}
              placement="right"
              overlay={texts["Entity"]}
              overlayStyle={{ background: "transparent" }}
              overlayInnerStyle={{
                borderRadius: "10px",
                font: '1em "DM Sans", Arial, Helvetica, sans-serif',
              }}
            >
              <label htmlFor="entity" className="form-label">
                Entity
              </label>
            </Tooltip>
            <Select
              name="entity"
              id="entity"
              width="173px"
              defaultValue={formik.values.entity}
              onChange={(entity) => {
                formik.setFieldValue("entity", parseInt(entity.target.value));
              }}
            >
              <option value={0}>Select entity</option>
              {lookups.entities.map((entity) => (
                <option key={entity.id} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </Select>
            {formik.errors.entity && formik.touched.entity ? (
              <Error>{formik.errors.entity}</Error>
            ) : null}
          </li>
        </ul>
      </div>
      <div className="field-row">
        <Tooltip
          showArrow={false}
          placement="right"
          overlay={texts["Description"]}
          overlayStyle={{ background: "transparent" }}
          overlayInnerStyle={{
            borderRadius: "10px",
            font: '1em "DM Sans", Arial, Helvetica, sans-serif',
          }}
        >
          <label htmlFor="description" className="form-label">
            Description
          </label>
        </Tooltip>
        <textarea
          id="description"
          name="description"
          className="form-control textarea"
          onChange={(description) => {
            formik.setFieldValue("description", description.target.value);
          }}
          value={formik.values.description}
        ></textarea>
        {formik.errors.description && formik.touched.description ? (
          <Error>{formik.errors.description}</Error>
        ) : null}
      </div>

      {isEdit && successResponse && (
        <div style={{ display: "block" }}>
          <RegularText size="16px" color="#2EC07F">
            Your project has been successfully {successText}!{" "}
            <Link to="/policies" style={{ fontSize: "16px", color: "#0379F2" }}>
              Click here
            </Link>{" "}
            to see list of projects.
          </RegularText>
        </div>
      )}

      <ButtonActions
        isEdit={isEdit}
        isLastTab={isLastTab}
        responseError={responseError}
        submitCount={formik.submitCount}
        onSetSelectedTab={setSelectedTab}
        nextTab="parameters"
        prevTap="topics"
      />
    </div>
  );
};

export { Information };
