import styled from "styled-components";

const DashHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  overflow: hidden;
  white-space: nowrap;
  
`;

const DashBar = styled.ul`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-size: ${(props) =>
    props.fontSizes ? props.fontSizes : props.theme.fontSizes.base};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semiBold};
  color: ${(props) => (props.color ? props.color : props.theme.colors.gray)};
`;

const Title = styled.h2`
  font-size: ${(props) =>
    props.fontSizes ? props.fontSizes : props.theme.fontSizes.extended};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeight.semiBold};

  color: ${(props) => (props.color ? props.color : props.theme.colors.gray)};
`;

export { DashHead, Text, Title, DashBar };
