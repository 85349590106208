import React, { useState } from "react";
import { Modal } from "UI/atoms/index";

import { Button } from "UI/atoms/index";
import { CheckIcon as Check } from "shared/assets/svgs/Check";
import { useDeleteLookup } from "queries/lookups";

const DeleteModal = ({
  setIsOpenDeleteModal,
  isDeleteModalOpen,
  lookup,
  name,
}) => {
  const { mutate: deleteLookup } = useDeleteLookup(lookup, name);

  const handleDeleteLookup = () => {
    deleteLookup();
    setIsOpenDeleteModal(false);
  };
  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        toggleModal={isDeleteModalOpen}
        width="500px"
        padding="2rem"
        className="fecha-aprobado"
      >
        <h4 style={{ lineHeight: "1.5" }}>
          Warning! Deleting the lookup{" "}
          <span style={{ color: "red" }}>{lookup.name}</span> will remove all
          associated bills, acts & laws.
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            width="133px"
            background="#F0F4F7"
            color="#344854"
            className="btn-with-icon"
            onClick={() => setIsOpenDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            width="133px"
            background="red"
            className="btn-with-icon white-icon"
            onClick={() => handleDeleteLookup()}
          >
            <Check /> <span>Confirm</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { DeleteModal };
