import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "../utils/api-client";

const useGetProjects = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: () => client("law_projects").then((data) => data),
  });
  return {
    data: data || [],
    isLoading,
  };
};

const useGetProjectById = (projectId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => client(`law_projects/${projectId}`).then((data) => data),
    enabled: !!projectId,
  });
  return {
    data: data ?? {},
    isLoading,
  };
};

const useCreateLawProject = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`law_projects/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("law_projects"),
    }
  );
};

const useMutateProjectStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`law_projects/${data.id}/`, {
        method: "PUT",
        data: { status: data.status },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("law_projects"),
    }
  );
};

const useEditLawProject = (projectId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`law_projects/${projectId}/`, {
        method: "PUT",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("law_projects"),
    }
  );
};

const useAddAttachmentToProject = (projectId) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      client(`law_projects/${data.id}/add_attachment/`, {
        method: "POST",
        data: data.file,
        headers: {
          "Content-Type": data.type,
          "Content-Disposition": `attachment; filename=${data.name}`,
          filename: data.name,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(["project", projectId]),
    }
  );
};

const useDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (projectId) =>
      client(`law_projects/${projectId}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(["projects"]),
      onSettled: () => queryClient.invalidateQueries(["projects"]),
    }
  );
};

const useDeleteAttachmentFromProject = (attachmentId) => {
  return useMutation(() =>
    client(`law_projects/del_attachment/${attachmentId}/`, {
      method: "DELETE",
    })
  );
};

const useDownloadAttachmentById = (attachmentId) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["attachment", attachmentId],
    queryFn: () =>
      client(`law_projects/attachment/${attachmentId}/`).then((data) => data),
    manual: true,
    enabled: !!attachmentId,
  });
  return {
    data: data ?? {},
    isLoading,
    refetch,
  };
};

export {
  useAddAttachmentToProject,
  useCreateLawProject,
  useDeleteAttachmentFromProject,
  useDeleteProject,
  useDownloadAttachmentById,
  useEditLawProject,
  useGetProjectById,
  useGetProjects,
  useMutateProjectStatus,
};
