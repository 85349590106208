

import React from 'react'

const Plus = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0379F2', width: '25px', height: '25px', borderRadius: '25px'}}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.1875 7H11.8125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7 2.1875V11.8125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

export {Plus}