import * as Yup from "yup";

const userSchemaValidator = (isEdit) => {
  return Yup.object()
    .shape({
      first_name: Yup.string().required("First name field is required"),
      last_name: Yup.string().required("Last name field is required"),
      email: Yup.string().required("Email field is required"),
      country: Yup.string().required("Country field is required"),
      is_active: Yup.bool().concat(
        !isEdit
          ? Yup.bool().required("Activity status field is required")
          : null
      ),
      role: Yup.string().required("Role field is required"),
    })
    .required();
};

export { userSchemaValidator };
