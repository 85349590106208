import {
  AddEditProject,
  Escritorio,
  Politicas,
  Reportes,
  Seguimiento,
} from "pages/index";
import { Navigate, Route, Routes } from "react-router-dom";

const CoordinatorRoutes = ({ projects }) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/desktop" />} />
      <Route
        exact
        path="/desktop"
        element={<Escritorio projects={projects} />}
      ></Route>
      <Route
        exact
        path="/policies"
        element={<Politicas projects={projects} />}
      ></Route>
      <Route exact path="/new-project" element={<AddEditProject />}></Route>
      <Route
        exact
        path="/edit-project/:id"
        element={<AddEditProject />}
      ></Route>
      <Route
        exact
        path="/reports"
        element={<Reportes projects={projects} />}
      ></Route>
      <Route
        exact
        path="/tracker"
        element={<Seguimiento projects={projects} />}
      ></Route>
    </Routes>
  );
};

const CoordinatorApp = ({ projects }) => {
  return (
    <>
      <CoordinatorRoutes projects={projects} />
    </>
  );
};

export { CoordinatorApp };
