import React from "react";
import RcSlider from "rc-slider";
import "rc-slider/assets/index.css";

const Slider = ({ yearsRange, setSelectedYears }) => {
  return (
    <RcSlider
      range
      allowCross={false}
      min={yearsRange[0]}
      max={yearsRange[yearsRange.length - 1]}
      step={1}
      defaultValue={yearsRange}
      railStyle={{ backgroundColor: "white", height: "8px" }}
      trackStyle={{ backgroundColor: "#0379F2", height: "8px" }}
      handleStyle={{
        backgroundColor: "#0379f2",
        border: "3px solid #ffffff",
        boxShadow: "1px 1px 10px 1px rgba(104, 123, 140, 0.25)",
        width: "18px",
        height: "18px",
        opacity: 1,
      }}
      onChange={(value) => setSelectedYears(value)}
    />
  );
};

export { Slider };
