const editProjectInitialValues = (project, isConsultant, userId) => {
  return {
    name: project.name ?? "",
    legal_id: project.legal_id ?? "",
    description: project.description ?? "",
    location: project.location?.id ?? null,
    status: project.status?.id ?? null,
    probability: project.probability?.id ?? null,
    impact: project.impact?.id ?? null,
    success: project.success?.id ?? null,
    entity: project.entity?.id ?? null,
    presented: Boolean(project.presented)
      ? new Date(`${project.presented} EDT`)
      : null,
    voted: Boolean(project.voted) ? new Date(`${project.voted} EDT`) : null,
    subtopics: project.subtopics ?? [],
    consultant: isConsultant ? [userId] : project.consultant ?? [],
    attachments: project.attachments ?? [],
    strategy_links: project.strategy_links ?? [],
  };
};

// const editProjectInitialValues = {};

const addProjectInitialValues = (isConsultant, userId) => ({
  name: "",
  legal_id: "",
  description: "",
  presented: null,
  voted: null,
  location: 0,
  status: 0,
  probability: 0,
  impact: 0,
  success: 0,
  entity: 0,
  subtopics: [],
  attachments: [],
  strategy_links: [],
  consultant: isConsultant ? [userId] : [],
});

export { editProjectInitialValues, addProjectInitialValues };
