import React from "react";
import styled from "styled-components";
import { Button } from "UI/atoms";
import { Error } from "UI/atoms";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <StyledError>
      <div>
        <p>Oops, something went wrong.</p>
        <p>
          Error code: <Error>{error.response?.status}</Error>
        </p>
        <p>
          Description: <Error>{error.response?.statusText}</Error>
        </p>
        <p style={{ marginTop: "40px" }}>
          Please{" "}
          <Button
            width="auto"
            padding={"5px"}
            style={{ display: "unset" }}
            background="transparent"
            color="#0379f2"
            onClick={() => resetErrorBoundary()}
          >
            try again
          </Button>
          and if the error persists, contact an administrator.
        </p>
      </div>
    </StyledError>
  );
};

const StyledError = styled.div`
  display: flex;
  align-items: center;

  background: #f0f4f7
  color: #413a44;

  & * {
    margin: 15px 0;
  }

  & p {
    font-size: 1.5em;
  }
`;

export { ErrorFallback };
