import React from 'react'

const Stack2Icon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 11L8 14.5L14 11" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 8L8 11.5L14 8" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 5L8 8.5L14 5L8 1.5L2 5Z" stroke="#0379F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {Stack2Icon}