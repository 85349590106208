import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bell, Plus } from "shared/assets/svgs/DesktopPageIcons";
import { PaperPlaneIcon } from "shared/assets/svgs/Others/PaperPlane";
import Avatar from "shared/images/avatar.jpg";
import { Button, Input, Search } from "UI/atoms/index";
import { Modal } from "UI/molecules/index";
import { DashBar, DashHead, Text, Title } from "./styles";

const Header = ({ userName, userRole, search, buttonText }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate()
  return (
    <DashHead>
      <Title fontSizes="18px" fontWeight={500} color="#1c2a34">
        {userName}
        <br />
        <Text fontSizes="14px" fontWeight={400} color="#687b8c">
          {userRole}
        </Text>
      </Title>
      <div>
        <DashBar>
          {/* <li>
            <Search
              width="450px"
              placeholder={search}
              isSearch={true}
              id="search"
              name="search"
              className="dash-search"
            />
          </li> */}
          <li>
            <Button
              onClick={() => navigate('/new-project')}
              width="174px"
              style={{ padding: "1rem 1.25rem" }}
              background="#0379F2"
              borderRadius="12px"
            >
              <Plus /> <span style={{ marginLeft: "9px" }}>{buttonText}</span>
            </Button>
          </li>
          {/* <li className="dash-alert">
            <span className="alert" />
            <Bell />
          </li>
          <li className="dash-avatar">
            <figure>
              <img src={Avatar} width="46" />
            </figure>
          </li> */}
        </DashBar>
      </div>
      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="30rem"
        height="20rem"
        title={"Invite Organization"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "2.3rem 6rem",
          }}
        >
          <Input
            margin="0 0 1rem 0"
            padding="0.75rem"
            border="0.5px solid #BCBCBC"
            borderRadius="10px"
            type="text"
            placeholder="Organization Name"
          />
          <Input
            padding="0.75rem"
            border="0.5px solid #BCBCBC"
            borderRadius="10px"
            type="email"
            placeholder="Email"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
          }}
        >
          <Button
            // style={{margin: '0 auto'}}
            fontSize="sm"
            padding="0.75rem 1.25rem"
            style={{ margin: "0 1rem" }}
            onClick={() => setIsOpenModal(false)}
          >
            <span style={{ marginRight: "0.5rem" }}>
              <PaperPlaneIcon />
            </span>
            Send Invitation
          </Button>
          <Button
            background="#F9F8FA"
            color="#3D4852"
            fontSize="sm"
            padding="1rem 1.5rem"
            style={{ margin: "0 1rem" }}
            onClick={() => setIsOpenModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </DashHead>
  );
};

export { Header };
