import React from 'react'

const PresentationChartIcon = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V16.5C3 16.9142 3.33579 17.25 3.75 17.25H20.25C20.6642 17.25 21 16.9142 21 16.5V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 17.25L18 21" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 17.25L6 21" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 11.25V13.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 9.75V13.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 8.25V13.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 4.5V2.25" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {PresentationChartIcon}