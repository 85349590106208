import { useState } from "react";
import { CaretRightIcon as CaretRight } from "shared/assets/svgs/CaretRight";
import { useColors, useCommonLookups } from "queries/lookups";
import { Spinner, Trash } from "shared/assets/svgs";
import { Badge, Button, Search, Select } from "UI/atoms/index";
import { Modal } from "UI/molecules/index";
import { ProjectDetail } from "./UI/templates/ProjectDetail";
import { TYPES } from "../../types/select";
import { filterProjects } from "../../utils/filterProjects";
import { usePaginator } from "hooks/usePaginator";
import { useDeleteProject } from "queries/projects";
import { useCleanFilters } from "hooks/useCleanFilters";

import { WarningModal } from "UI/molecules/WarningModal";

function Politicas({ projects }) {
  const [isWarningModalOpened, setIsOpenWarningModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { selects, setSelects, cleanFilters } = useCleanFilters();
  const [project, setProject] = useState({});

  const { data: colors, isLoading: isColorsLoading } = useColors();
  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("topics");
  const { data: statuses, isLoading: isStatusesLoading } =
    useCommonLookups("project_status");
  const { data: probabilities, isLoading: isProbabilitiesLoading } =
    useCommonLookups("probability_levels");
  const { data: impacts, isLoading: isImpactsLoading } =
    useCommonLookups("impact_levels");

  const { mutate: deleteProject, isLoading: isDeleteProjectLoading } =
    useDeleteProject();

  const filteredProjects = filterProjects(projects, selects);
  const { items, pages, pagesList, setPageNumber } = usePaginator(
    filteredProjects,
    10
  );

  const handleColors = (projectLookupColorId) => {
    return colors?.find((color) => color.id === projectLookupColorId).code;
  };

  const handleProject = (project) => {
    setIsOpenModal(true);
    setProject(project);
  };

  const handleOpenWarningModal = (project) => {
    setIsOpenWarningModal(true);
    setProject(project);
  };

  const handleTypes = (lookupField) => {
    const hasTypeDefined = !!TYPES[lookupField];
    return {
      background: hasTypeDefined ? TYPES[lookupField].background : "#0379F2",
      name: hasTypeDefined ? TYPES[lookupField].name : lookupField,
      color: hasTypeDefined ? TYPES[lookupField].color : "#FFFFFF",
    };
  };

  if (isColorsLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="politicas-dashboard">
        <div className="dash-area">
          <p className="breadcrumb">Bills, Acts & Laws</p>
          <ul className="dash-table-actions">
            <li>
              <Select
                className="select-holder"
                name="pais"
                labelText={"Country"}
                onChange={(e) =>
                  setSelects((prevState) => ({
                    ...prevState,
                    location: { name: e.target.value },
                  }))
                }
              >
                <option value="" disabled selected></option>
                {isCountriesLoading ? (
                  <Spinner />
                ) : (
                  countries?.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))
                )}
              </Select>
            </li>
            <li>
              <Select
                className="select-holder"
                name="tipo"
                labelText={"Subject Area"}
                onChange={(e) =>
                  setSelects((prevState) => ({
                    ...prevState,
                    topic: { name: e.target.value },
                  }))
                }
              >
                <option value="" disabled selected></option>
                {isTopicsLoading ? (
                  <Spinner />
                ) : (
                  topics?.map((topic) => (
                    <option key={topic.id} value={topic.name}>
                      {topic.name}
                    </option>
                  ))
                )}
              </Select>
            </li>
            <li>
              <Select
                className="select-holder"
                name="estado"
                labelText={"Status"}
                onChange={(e) =>
                  setSelects((prevState) => ({
                    ...prevState,
                    status: { name: e.target.value },
                  }))
                }
              >
                <option value="" disabled selected></option>
                {isStatusesLoading ? (
                  <Spinner />
                ) : (
                  statuses?.map((status) => (
                    <option key={status.id} value={status?.name}>
                      {handleTypes(status?.name).name}
                    </option>
                  ))
                )}
              </Select>
            </li>
            <li>
              <Select
                className="select-holder"
                name="probabilidad"
                labelText={"Chances Of Approval"}
                onChange={(e) =>
                  setSelects((prevState) => ({
                    ...prevState,
                    probability: { name: e.target.value },
                  }))
                }
              >
                <option value="" disabled selected></option>
                {isProbabilitiesLoading ? (
                  <Spinner />
                ) : (
                  probabilities?.map((probability) => (
                    <option key={probability.id} value={probability.name}>
                      {probability.name}
                    </option>
                  ))
                )}
              </Select>
            </li>
            <li>
              <Select
                className="select-holder"
                name="impacto"
                labelText={"Impact"}
                onChange={(e) =>
                  setSelects((prevState) => ({
                    ...prevState,
                    impact: { name: e.target.value },
                  }))
                }
              >
                <option value="" disabled selected></option>
                {isImpactsLoading ? (
                  <Spinner />
                ) : (
                  impacts?.map((impact) => (
                    <option key={impact.id} value={impact.name}>
                      {handleTypes(impact.name).name}
                    </option>
                  ))
                )}
              </Select>
            </li>
            {/* <li>
                <Button height="40px" width="126px" className="btn-with-icon">
                  <CloudArrowDown /> <span>Download</span>
                </Button>
              </li> */}
          </ul>
          <li style={{ display: "flex", justifyContent: "flex-end" }}>
            <Badge
              color="#687b8c"
              background="rgba(104, 123, 140, 0.1)"
              className="badge"
              padding="9px 15px"
              margin="0 0 0 0.5rem"
              onClick={cleanFilters}
            >
              Clean filters
            </Badge>
          </li>
          <table className="dash-table" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th className="nowrap">
                  <span className="label">DATE PROPOSED </span>
                </th>
                <th>LEGISLATION TITLE</th>
                <th className="nowrap">
                  <span className="label">SUBJECT AREA </span>
                </th>
                <th className="nowrap">
                  <span className="label">COUNTRY </span>
                </th>
                <th className="nowrap">
                  <span className="label">STATUS </span>
                </th>
                <th className="nowrap">
                  <span className="label">CHANCES OF APPROVAL </span>
                </th>
                <th className="nowrap">
                  <span className="label">IMPACT </span>
                </th>
                <th className="nowrap">
                  <span className="label">DETAIL </span>
                </th>
                <th className="nowrap">
                  <span className="label">DELETE </span>
                </th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {items?.map((project) => (
                <tr key={project.id}>
                  <td>{project.legal_id}</td>
                  <td className="nowrap">{project.presented}</td>
                  <td>
                    <strong>{project.name}</strong>
                  </td>
                  <td>{project.subtopics[0].topic.name}</td>
                  <td className="nowrap">{project.location.name}</td>
                  <td className="nowrap">
                    <Badge
                      background={handleColors(project.status.color)}
                      className="badge estado"
                    >
                      {project.status.name}
                    </Badge>
                  </td>
                  <td className="nowrap center">
                    <Badge
                      background={handleColors(project.probability.color)}
                      className="badge"
                    >
                      {project.probability.name}
                    </Badge>
                  </td>
                  <td className="nowrap center">
                    <Badge
                      background={handleColors(project.impact.color)}
                      color={handleTypes(project.impact.name).color}
                      className="badge"
                    >
                      {project.impact.name}
                    </Badge>
                  </td>
                  <td>
                    <Button
                      height="30px"
                      width="30px"
                      className="circle-btn"
                      onClick={() => handleProject(project)}
                    >
                      <CaretRight />
                    </Button>
                  </td>
                  <td>
                    <Button
                      type="button"
                      height="30px"
                      width="30px"
                      background="#FF562F"
                      className="circle-btn"
                      onClick={() => handleOpenWarningModal(project)}
                    >
                      <Trash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {pagesList.map((lof) => (
              <Badge
                key={lof}
                background="white"
                color="#0379F2"
                fontWeight="bold"
                onClick={() => setPageNumber(lof)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 0.25rem",
                }}
              >
                {lof}
              </Badge>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggleModal={setIsOpenModal}
        width="610px"
        padding="25px 32px"
        className="politicas-ficha"
      >
        <ProjectDetail
          setIsOpenModal={setIsOpenModal}
          projectId={project.id}
          colors={colors}
        />
      </Modal>

      <WarningModal
        setIsOpenWarningModal={setIsOpenWarningModal}
        isWarningModalOpened={isWarningModalOpened}
        mutation={deleteProject}
        id={project.id}
        isLoading={isDeleteProjectLoading}
        message="Warning! You will delete this project. Are you sure you want to continue?"
      />
    </>
  );
}

export { Politicas };
