const filterUsers = (users = [], selects = {}) => {
    const mappedUsers = users.map(user => {
        return {
            ...user,
            country: user.country.name,
            country_id: user.country.id,
            role: user.role,
            is_active: user.is_active
        }
    })
    return mappedUsers.filter(user => {
      for (const key in selects) {
        if (selects[key] !== user[key])
          return false;
      }
      return true;
    });
  } 
  
  export {filterUsers}