import { useAsync } from "hooks/useAsync";
import * as React from "react";
import { Spinner } from "shared/assets/svgs/index";
import * as auth from "./provider";
import jwt_decode from "jwt-decode";
import { login } from "utils/api-client";

const SSO_URL = process.env.REACT_APP_SSO_SERVICE_PROVIDER_URL;

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const {
    data: user,
    status,
    isLoading,
    isIdle,
    isSuccess,
    isError,
    run,
    setData,
  } = useAsync();

  // this might look weird but take in count that this is a really special and weird case.
  // the users use a service named SSO where they log in from it and then the server redirects
  // the user to this app.
  // This is why we execute the login method in each page refresh and directly set the data in the context
  // without any action made in our app.
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token")
      ? searchParams.get("token")
      : localStorage.getItem("__auth_provider_refresh__");

    run(
      login({
        refresh: token,
      }).then((res) => jwt_decode(res))
    );
  }, []);

  // const login = React.useCallback(
  //   (form) => auth.login("/login", form).then((user) => setData(user)),
  //   [setData]
  // );

  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
    window.location.href = SSO_URL;
  }, [setData]);

  const value = React.useMemo(
    () => ({ user, login, logout }),
    [login, logout, user]
  );

  if (isLoading || isIdle) {
    return <Spinner width="38" height="38" isFull />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  if (isError) {
    window.location.href = SSO_URL;
  }

  throw new Error(`Unhandled status: ${status}`);
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
