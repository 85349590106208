import { useState } from "react";
import Circle from "../../UI/molecules/ProgressWheel/Circle.js";
import TreeMap from "../../UI/molecules/TreeMap";
import Scatter from "../../UI/molecules/Scatter";
import Bar from "../../UI/molecules/Bar";
import Card from "UI/molecules/Card/index.js";
import { SubtemaBar } from "UI/atoms/SubtemaBars/SubtemaBar.js";
import { useCommonLookups } from "queries/lookups";
import { Spinner } from "shared/assets/svgs";
import { Select, Slider, Badge } from "UI/atoms";
import { IMPACTS_TYPES } from "../../types/select";
import { filterProjects } from "../../utils/filterProjects";
import { getSubtopicsAmountPerTopic } from "../../utils/getSubtopicsAmountPerTopic";
import { getYearsRange } from "../../utils/getYearsRange";
import { getGroupedProjectsPerStatus } from "../../utils/getGroupedProjectsPerStatus";
import { getProjectsAmountPerStatus } from "utils/getProjectsAmountPerStatus";
import { useColors } from "queries/lookups";
import { useCleanFilters } from "hooks/useCleanFilters";

function Reportes({ projects }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: countries, isLoading: isCountriesLoading } =
    useCommonLookups("locations");
  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("topics");
  const { data: impacts, isLoading: isImpactsLoading } =
    useCommonLookups("impact_levels");
  const { data: entities, isLoading: isEntitiesLoading } =
    useCommonLookups("entities");

  const { selects, setSelects, cleanFilters } = useCleanFilters();

  const yearsRange = getYearsRange(projects);
  const [selectedYears, setSelectedYears] = useState([
    yearsRange[0],
    yearsRange[yearsRange.length - 1],
  ]);

  const filteredProjects = filterProjects(projects, selects, selectedYears);

  const {
    results: subtopicsPerFilteredProjects,
    tree,
    resultsByRejectedOrApprovedStatus,
  } = getSubtopicsAmountPerTopic(filteredProjects);

  const { groupedDataByStatus, groupedTopics } = getGroupedProjectsPerStatus(
    filteredProjects,
    selectedYears
  );

  const uniqueCountries = [
    ...new Set(filteredProjects.map((item) => item.location)),
  ];

  const { total: totalProjectsByAllStatus, results: projectsByStatus } =
    getProjectsAmountPerStatus(filteredProjects);

  const { data: colors } = useColors();
  const handleColors = (projectLookupColorId) => {
    return colors?.find((color) => color.id === projectLookupColorId)?.code;
  };

  return (
    <div className="reportes-dashboard">
      <div className="dash-area">
        <p className="breadcrumb">Reports</p>
        <div className="reportes">
          <div className="reportes-topbar">
            <div className="years">
              <label>Project Year:</label>
              <div className="block">
                <div className="from-to">
                  <span className="year">{selectedYears[0]}</span>
                  <span className="year">
                    {selectedYears[selectedYears.length - 1]}
                  </span>
                </div>
                <Slider
                  yearsRange={yearsRange}
                  setSelectedYears={setSelectedYears}
                />
              </div>
            </div>
            <ul className="filters">
              <li>
                <Select
                  className="select-holder"
                  name="pais"
                  labelText={"Country"}
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      location: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled selected></option>
                  {isCountriesLoading ? (
                    <Spinner />
                  ) : (
                    countries?.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))
                  )}
                </Select>
              </li>
              <li>
                <Select
                  className="select-holder"
                  name="impacto"
                  labelText={"Impact"}
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      impact: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled selected></option>
                  {isImpactsLoading ? (
                    <Spinner />
                  ) : (
                    impacts?.map((impact) => (
                      <option key={impact.id} value={impact.name}>
                        {IMPACTS_TYPES[impact.name]}
                      </option>
                    ))
                  )}
                </Select>
              </li>
              <li>
                <Select
                  className="select-holder"
                  name="tema"
                  labelText={"Area Of Interest"}
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      topic: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled selected></option>
                  {isTopicsLoading ? (
                    <Spinner />
                  ) : (
                    topics?.map((topic) => (
                      <option key={topic.id} value={topic.name}>
                        {topic.name}
                      </option>
                    ))
                  )}
                </Select>
              </li>
              <li>
                <Select
                  className="select-holder"
                  name="entidad"
                  labelText={"Entity"}
                  onChange={(e) =>
                    setSelects((prevState) => ({
                      ...prevState,
                      entity: { name: e.target.value },
                    }))
                  }
                >
                  <option value="" disabled selected></option>
                  {isEntitiesLoading ? (
                    <Spinner />
                  ) : (
                    entities?.map((entity) => (
                      <option key={entity.id} value={entity.name}>
                        {entity.name}
                      </option>
                    ))
                  )}
                </Select>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Badge
                    color="#687b8c"
                    background="rgba(104, 123, 140, 0.1)"
                    className="badge"
                    padding="9px 15px"
                    margin="18px 0 0 0.5rem"
                    onClick={cleanFilters}
                  >
                    Clean filters
                  </Badge>
                </div>
              </li>
            </ul>
          </div>
          <div className="reportes-cols">
            <div className="main">
              <ul>
                <li>
                  <h1>{filteredProjects?.length || 0}</h1>
                  <p>
                    TOTAL
                    <br /> PROJECTS
                  </p>
                </li>
                <li>
                  <h1>{uniqueCountries?.length || 0}</h1>
                  <p>COUNTRIES</p>
                </li>
              </ul>
            </div>
            <div className="estadisticas">
              <div className="row-card">
                <div className="card halfcard">
                  <Card
                    capText="Status of legislation"
                    embedComp={
                      <ul className="circle-widgets">
                        {projectsByStatus?.map((status) => (
                          <li>
                            <Circle
                              percentage={(
                                (status.count / totalProjectsByAllStatus || 0) *
                                100
                              ).toFixed(0)}
                              color={handleColors(status.color)}
                              title={status.status}
                            />
                          </li>
                        ))}
                      </ul>
                    }
                  />
                </div>
                <div className="card halfcard">
                  <Card
                    capText="Ranking subtopics"
                    embedComp={
                      <div className="ranking">
                        <div className="head">
                          <h4>Subtopic</h4>
                          <h4>Quantity</h4>
                        </div>
                        <ul>
                          {subtopicsPerFilteredProjects
                            .sort((a, b) => b.count - a.count)
                            .map((p, index) => {
                              return (
                                <li key={index}>
                                  <SubtemaBar
                                    subtipo={`${p.name} - (${p.topic})`}
                                    cantidad={p.count}
                                  />
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="row-card">
                <div className="card halfcard">
                  {" "}
                  <Card
                    capText="Average approval timespan per topic (weeks)"
                    embedComp={
                      <Bar
                        data={resultsByRejectedOrApprovedStatus.filter(
                          (project) => project.status === "Passed/Approved"
                        )}
                      />
                    }
                  />
                </div>
                <div className="card halfcard">
                  <Card
                    capText="Average rejection timespan per topic (weeks)"
                    embedComp={
                      <Bar
                        data={resultsByRejectedOrApprovedStatus.filter(
                          (project) => project.status === "Rejected"
                        )}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row-card">
                <div className="card">
                  <Card
                    capText="Projects overview and reported impact, across topics"
                    embedComp={
                      <Scatter
                        data={groupedDataByStatus}
                        topics={groupedTopics}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row-card">
                <div className="card">
                  <Card
                    capText="Distribution of legislation by topic and subtopic"
                    embedComp={<TreeMap tree={tree} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Reportes };
