const filterProjects = (projects = [], selects = {}, yearsRange = []) => {
  const mappedProjects = projects.map((project) => {
    return {
      ...project,
      topic: {
        name: project.subtopics[0].topic.name,
        id: project.subtopics[0].topic.id,
      },
      // Replace for this other `topic` in case we don't want to handle topic hierarchy
      /*
      topic: project.subtopics.map((s) => {
        return { name: s.topic.name };
      }),
      */
    };
  });

  return mappedProjects.filter((project) => {
    for (const key in selects) {
      const normalizedProjectStr = (obj, key) => {
        return obj[key]["name"].toLowerCase();
      };
      if (project[key] === undefined) {
        return false;
      } else {
        // Uncomment in case we don't want to handle topic hierarchy
        /*
        if (key !== "topic") {
        */
        if (
          project[key] === undefined ||
          normalizedProjectStr(project, key) !=
            normalizedProjectStr(selects, key)
        ) {
          return false;
        }
        // Uncomment in case we don't want to handle topic hierarchy
        /*
        } else if (
          !project.topic.some((t) => {
            return t.name.toLowerCase() === selects[key]["name"].toLowerCase();
          })
        ) {
          return false;
        }
        */
      }
    }

    // Return only the ones within the selected years range
    if (yearsRange && yearsRange.length > 0) {
      const presentedYear = new Date(project.presented).getFullYear();
      if (
        presentedYear < yearsRange[0] ||
        presentedYear > yearsRange[yearsRange.length - 1]
      ) {
        return false;
      }
    }
    return true;
  });
};

export { filterProjects };
