import React from 'react'
import { Modal } from 'UI/atoms'
import { AddEditTopic } from '../UI/templates'

const AddEditTopicModal = ({isOpenModal, setOpenAddOrEditModal, method, topic, subtopics, keyName, name}) => {
  return (
    <Modal 
      isOpen={isOpenModal}
      toggleModal={setOpenAddOrEditModal}
      width='550px'
      height='auto'
      padding='25px 32px'
      className='topicos-ficha'
    >
      {method === 'PUT' && 
        <AddEditTopic 
          actionText='Edit' 
          method='PUT' 
          topic={topic} 
          subtopics={subtopics}
          setOpenAddOrEditModal={setOpenAddOrEditModal} 
          keyName={keyName}
          name={name}
        />
      }
      {method === 'POST' && 
        <AddEditTopic 
          actionText='Add' 
          method='POST' 
          setOpenAddOrEditModal={setOpenAddOrEditModal} 
          keyName={keyName}
          name={name}
        />
      }
    </Modal>
  )
}

export {AddEditTopicModal}
    
