import React from "react";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";

export default function Circle({ percentage, color, title }) {
  return (
    <div
      style={{
        marginRight: "8px",
        whiteSpace: "nowrap",
        justifyContent: "center",
        textAlign: "center",
        fontSize: "13px",
        lineHeight: "16px",
        fill: "#1C2A34",
      }}
    >
      <CircularProgressBar
        colorCircle="#e6e6e6"
        colorSlice={color}
        percent={Number(percentage)}
        round
        size={80}
        strokeBottom={5}
        stroke={5}
        textPosition="0.35em"
        fontSize={"20px"}
      />
      <p>{title}</p>
    </div>
  );
}
