import { useState } from "react";

const useCleanFilters = () => {
  const [selects, setSelects] = useState({});

  const cleanFilters = () => {
    const options = document.querySelectorAll("select");
    options.forEach((option) => {
      option.value = option.options[0].value;
    });
    setSelects({});
  };

  return { selects, setSelects, cleanFilters };
};

export { useCleanFilters };
