import React, { useState } from "react";
import { Select, ListItem, Error, Button } from "UI/atoms";
import { Trash } from "shared/assets/svgs";
import { CaretRightIcon as CaretRight } from "shared/assets/svgs/CaretRight";
import { useCommonLookups } from "queries/lookups";
import { Link } from "react-router-dom";
import { RegularText } from "UI/atoms";
import { ButtonActions } from "../../organisms/ButtonActions";

const Topics = ({
  formik,
  setSelectedTab,
  isEdit,
  responseError,
  successResponse,
  isLastTab,
  isCreateLawProjectSuccess,
  isEditLawProjectSuccess,
}) => {
  const { subtopics } = formik.values;

  const successText = isEdit ? "changed" : "created";

  const { data: topics, isLoading: isTopicsLoading } =
    useCommonLookups("subtopics");
  const [errors, setErrors] = useState("");
  const [topicId, setTopicId] = useState(topics[0]?.topic.id);

  const topicsSelect = topics?.reduce((acc, el) => {
    if (acc.some((accEl) => accEl.topic.id === el.topic.id)) return acc;
    else return [...acc, el];
  }, []);

  const subtopicsByTopicId = topics?.filter(
    (topic) => topic.topic.id === topicId
  );

  const deleteSelectedSubtopic = (subtopicId) => {
    const filteredSubtopicsSelected = subtopics?.filter(
      (subtopic) => subtopic.id !== subtopicId
    );
    formik.setFieldValue("subtopics", filteredSubtopicsSelected);
  };

  const handleSelectedSubtopic = (subtopic) => {
    if (
      subtopics?.some(
        (selectedSubtopic) => selectedSubtopic.name === subtopic.name
      )
    ) {
      setErrors("subtopic-already-selected");
      return;
    }

    if (subtopics?.length > 0) {
      const currentValues = formik.getFieldProps("subtopics");
      const newValue = [
        ...currentValues?.value,
        { ...subtopic, topic_name: subtopicsByTopicId.name },
      ];
      formik.setFieldValue("subtopics", newValue);
    } else {
      formik.setFieldValue("subtopics", [
        { ...subtopic, topic_name: subtopicsByTopicId.name },
      ]);
    }

    setErrors("");
  };

  return (
    <div className="tablayer tab-asignacion">
      <div className="cols">
        <div className="col">
          <div className="search-area">
            <li>
              <label htmlFor="topic" className="form-label">
                Type
              </label>
              <Select
                name="topic"
                id="topic"
                width="173px"
                defaultValue={topicId}
                onChange={(e) => setTopicId(parseInt(e.target.value))}
              >
                <option value={0}>Select type</option>
                {topicsSelect?.map((topic) => (
                  <option key={topic.topic.id} value={topic.topic.id}>
                    {topic.topic.name}
                  </option>
                ))}
              </Select>
              {formik.errors.topic && formik.touched.topic ? (
                <Error>{formik.errors.topic}</Error>
              ) : null}
            </li>
          </div>
          <ul className="asignaciones">
            {subtopicsByTopicId?.map((subtopic) => (
              <li key={subtopic.id}>
                <ListItem className="item" justifyContent="space-between">
                  <p>{subtopic.name}</p>
                  <Button
                    type="button"
                    height="26px"
                    width="26px"
                    className="circle-btn"
                    onClick={() => handleSelectedSubtopic(subtopic)}
                  >
                    <CaretRight />
                  </Button>
                </ListItem>
              </li>
            ))}
            {errors === "subtopic-already-selected" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Subtopic has already been added
              </span>
            ) : null}
          </ul>
        </div>
        <div className="col asignados-col">
          <h3>Topic and subtopic selected:</h3>
          <ul className="asignados">
            {subtopics?.map((subtopic) => (
              <li key={subtopic.id}>
                <ListItem className="item">
                  <p>{subtopic.name}</p>
                  <h4>{subtopic.topic_name}</h4>
                  <Button
                    type="button"
                    height="30px"
                    width="30px"
                    background="#FF562F"
                    className="circle-btn"
                    onClick={() => deleteSelectedSubtopic(subtopic.id)}
                  >
                    <Trash />
                  </Button>
                </ListItem>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {formik.errors.subtopics && formik.touched.subtopics ? (
        <Error>{formik.errors.subtopics}</Error>
      ) : null}

      {successResponse && (
        <div style={{ display: "block", marginTop: "20px" }}>
          <RegularText size="16px" color="#2EC07F">
            Your project has been successfully {successText}!{" "}
            <Link to="/policies" style={{ fontSize: "16px", color: "#0379F2" }}>
              Click here
            </Link>{" "}
            to see list of projects.
          </RegularText>
        </div>
      )}

      <ButtonActions
        isEdit={isEdit}
        isLastTab={isLastTab}
        responseError={responseError}
        // hasErrors={errorsArr.length > 0}
        onSetSelectedTab={setSelectedTab}
        isCreateLawProjectSuccess={isCreateLawProjectSuccess}
        nextTab="asignation"
        prevTap="attachments"
      />
    </div>
  );
};

export { Topics };
