import * as Yup from "yup";

const formSchemaValidator = Yup.object().shape({
  name: Yup.string().required("Name field is required"),
  legal_id: Yup.string().required("ID field is required"),
  description: Yup.string().required("Description field is required"),
  location: Yup.number()
    .positive("Location field is required")
    .required("Location field is required"),
  status: Yup.number()
    .positive("Status field is required")
    .required("Status field is required"),
  probability: Yup.number()
    .positive("Probability field is required")
    .required("Probability field is required"),
  impact: Yup.number()
    .positive("Impact field is required")
    .required("Impact field is required"),
  success: Yup.number()
    .positive("Success field is required")
    .required("Success field is required"),
  entity: Yup.number()
    .positive("Entity field is required")
    .required("Entity field is required"),
  presented: Yup.date().typeError("Presented date field is required"),
  subtopics: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        topic: Yup.object().shape({
          id: Yup.number(),
          name: Yup.string(),
        }),
      })
    )
    .min(1)
    .test(
      "unique-ids",
      "Los IDs de los elementos no deben repetirse",
      (value) => {
        if (!value || !Array.isArray(value)) {
          return true; // La validación no se aplica si el valor no es un array
        }
        const ids = value.map((item) => item.id);
        return new Set(ids).size === ids.length; // Comprobar si todos los IDs son únicos
      }
    ),
  consultant: Yup.array()
    .min(1)
    .test("unique-ids", "Elements must not be repeated", (value) => {
      if (!value || !Array.isArray(value)) {
        return true; // La validación no se aplica si el valor no es un array
      }
      const ids = value.map((item) => item.id);
      return new Set(ids).size === ids.length; // Comprobar si todos los IDs son únicos
    }),
});

export { formSchemaValidator };
